.settings_drawer_container {
    width: 320px;
    box-sizing: border-box;
    min-width: 0;
    height: 100%;
    z-index: 10;
    position: absolute;
    right: 0;
    background: var(--secondary-background-color);
    padding: 24px;
    box-shadow: 0 22px 20px 0 var(--shadow-default-color);
}