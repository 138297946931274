.fileDetails {
    display: flex;
    width: 90%;
    img {
      width: 35px;
      height: 45px;
    }
    .fileNameAndPath {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
    }
  }