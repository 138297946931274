.footerContainer {
    font-size: 18px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contentContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;

        .linkContainer {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;

            :global {
                .svg-inline--fa {
                    margin: 0 10px;
                    font-size: 8px;
                }
            }
        }
    }
    
    .copyrightContainer {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3);

        :global {
            .svg-inline--fa {
                margin-right: 5px;
            }
        }
    }

    .socialContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .socialButton {
            width: 40px;
            height: 40px;
            border: 1px solid rgba(0, 0, 0, 0.54);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px;
        }
    }
}

.disclaimer {
    padding: 20px;
    font-size: 12px;
    position: relative;
    z-index: 99999;
    background-color: white;
    color: #565656;
    border-top: 1px solid;
}
