.numberCell {
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    .plusIcon {
      display: block;
    }
    .smNumbersIcon {
      display: block;
    }
  }
  width: 100%;
  transition: 0.2s;
  padding: 0 5px;
  height: 35px;
  position: relative;
  cursor: text;

  input {
    width: 100%;
    border: 0;
    padding: 4px;
    outline: none;
    border-radius: 3px;
    background-color: transparent;
    margin: 0 auto;
    text-align: center;
    display: block;
    z-index: 2;
    color: #819ba8;
  }
  .addIcon {
    width: 16px;
    visibility: hidden;
  }
  &:hover svg {
    visibility: visible;
  }
  .addIcon {
    position: absolute;
    left: 45%;
    transform: translate(-45%, -50%);
    & circle {
      fill: url(#SVGID_1_);
    }
    & path {
      fill: #ffff;
    }
  }

}

.smNumbersIcon {
  display: none;
  position: absolute;
  left: 60%;
  font-size: 18px;
  transform: translate(-60%, -50%);
  color: lightgrey;
}

.clearIcon {
  display: none;
  color: #819ba8;
  position: absolute;
  right: 10%;
  cursor: pointer;
  transform: translate(50%, -45%);
  font-size: 15px;
  z-index: 3;
}
.numberCell:hover .clearIcon {
  display: block;
}
.numberCell input:focus + .numbersIcon {
  display: none;
}
.numberCell input:focus + .plusIcon {
  display: none;
}
.numberCell:hover input {
  color: var(--primary);
}
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type=number] {
//   -moz-appearance: textfield;
// }
