.scrollContainer {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 30vh;
    margin-top: 10px;
}

.scrollContainer::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    border-radius: 10px;
}

.scrollContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 10px;
}