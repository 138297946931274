.send_message_node_wrapper {
    width: 200px;
    min-height: 100px;
    padding: 4px;
    border-radius: 5px;
}

.send_message_source_handle {
    background-color: #E95B69 !important;
    z-index: 3;
}


.send_message_source_handle:hover {
    border: 3px solid #E95B69 !important;
    background-color: white !important;
}

.message_contact_source_handle {
    background-color: #008080 !important;
    z-index: 3;
}


.message_contact_source_handle:hover {
    border: 3px solid #008080 !important;
    background-color: white !important;
}