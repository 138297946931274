.linkContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: text;
    .clearIcon {
        display: none;
        color: #819ba8;
        position: absolute;
        right: 10%;
        cursor: pointer;
        transform: translate(50%, 10%);
        font-size: 15px;
        z-index: 3;
    }
    &:hover .clearIcon {
        display: block;
    }
    .addIcon,
    .linkIcon {
        width: 16px;
        visibility: hidden;
    }
    &:hover svg {
        visibility: visible;
    }
    .linkCell {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
   
        .linkIcon {
            padding-left: 2px;
            path {
                fill: var(----primary);
            }
        }      
        .addIcon circle {
            fill: url(#SVGID_1_);
        }
        .addIcon path {
            fill: #ffff;
        }
        a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 5px;
            font-size: 12px;
            letter-spacing: -0.4px;
            color: #52bbe8;
        }
    }
}

