// .box {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 890px;
//   max-width: 100%;
//   box-sizing: border-box;
//   box-shadow: 24px;
//   color: #3b4a54;
//   padding: 24px;
//   border-radius: 8px;
// }

.close_button_container {
  width: 100%;
  span {
    cursor: pointer;
  }
}

.content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  height: calc(100% - 36px);
}


.qr_and_video {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 235px;
  flex-shrink: 0;
}



.howto_scan {
  font-size: 13px;
  color: #53BDEB;
  text-align: center;
  cursor: pointer;
  margin-top: 8px;
}

.howto_scan:hover {
  text-decoration: underline;
}


.checkbox {
  height: unset;
  position: relative;
  top: 1px;
  margin-inline-end: 4px;
  margin-top: 40px;
}


.dismiss_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 1px #F1F1F1;
  width: fit-content;
  background: var(--background-default);
  margin-inline: auto;
  font: inherit;
  margin-top: 16px;
}

.dismiss_button:hover {
  background: #f8f8f8;
}
.dismiss_button:active {
  background: #f0f0f0;
}

.qrContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
  }
}

.loading {
  font-weight: bold;
  display:inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

.loadingClean {
  display:inline-block;
  clip-path: inset(0 1.1ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}