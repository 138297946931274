.edit_label_wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  width: 200px;
  flex-direction: row;
  // background-color: var(--secondary-background-color);
}

.label_wrapper:hover {
  & .drag_handle {
    color: var(--drag-handle);
    opacity: 0.5;
  }

  & .delete_button {
    opacity: 0.5;
    color: var(--drag-handle);
  }

  & .label_text_field {
    border: 1px dashed var(--text-color);
    // margin: 9px -1px;
  }
}

.mapped_labels {
  position: relative;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: var(--secondary-background-color);
}

// .label_wrapper {
//   // position: relative;
//   width: 152px;
// }

.text_field_wrap {
  display: flex;
  align-items: center;
  // background-color: var(--secondary-background-color);
  // border: 1px solid #ddd;
  border-radius: 3px;
  box-sizing: border-box;
  width: auto;

}

.label_accent {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  // margin-left: 7px;
  height: 25px;
  width: 25px;
  margin-inline-end: 5px;
}

.label_text_field {
  background-color: transparent;
  width: 95px;
  height: 24px;
  border: 0;
  // background-color: var(--secondary-background-color);
  border-top-left-radius: 3px;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: var(--secondary-text-color);
  border-radius: 3px;
  // margin-top: 10px;
  // margin-bottom: 10px;
  // padding-left: 4px;
  outline: none;
  height: 30px;
  border: 1px solid transparent;
}

.label_text_field:hover:focus {
  border: 1px solid #0097ff;
  background-color: var(--secondary-background-color);

}

.label_text_field:focus {
  border: 1px solid #0097ff;
  background-color: var(--secondary-background-color);
}

.remove_label_wrapper {
  // position: relative;
  // z-index: 20;
  // // right: -20px;
  // top: 5px;
  // width: 0;
  // padding: 0;
  padding-inline-start:5px
}

.delete_button {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background-color: transparent;
  border: 0;
  outline: none;
  margin: 5px 0;
  padding-top: 1px;
  // padding-right: 10px;
  border-radius: 3px;
  font-size: 16px;
  opacity: 0;
}

// .text_field_wrap:hover.delete_button{
//     display: block;
//   color: red !important;
// }

.add_new_label_button {
  // margin: 5px 15px !important;
  width: 152px;
  text-transform: capitalize !important;
  cursor: pointer !important;
  // background-color: var(--secondary-background-color) !important;
  border-radius: 3px;
  font-size: 13px !important;
  font-weight: 600 !important;
  border: 1px solid #ddd !important;
  outline: none;
  display: flex;
  justify-content: center;
}

.apply_changes_button_wrap {
  padding: 9px 6px 0px 6px;
  border-top: 1px solid #ddd;
  margin-top: 5px;
  margin-left: 24px;
  margin-right: 24px;
}

.apply_changes_button {
  cursor: pointer;
  padding: 4px 10px !important;
  // background-color: var(--secondary-background-color) !important;
  // border-radius: 4px !important;
  margin-top: 3px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 100% !important;
  // border: 1px solid #ddd !important;
  outline: none !important;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.apply_changes_button:hover {
  background-color: var(--x-hover) !important;
}

.drag_handle {
  cursor: pointer;
  // width: 15px !important;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: flex-start;
  height: 36px;
  outline: none;
  opacity: 0;
  // background-color: transparent;
}