@import "../../common.scss";

.planListContainer {
  display: flex;
  justify-content: center;

  .planContainer {
    // padding: 50px;
    // border: 1px solid $main;
    border-radius: 16px !important;
    box-shadow: 0 2px 1px rgb(83 189 235 / 30%);
    color: var(----primary);
    margin: 0 1%;
    width: 25%;
    min-width: 190px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px 15px 30px;
    overflow: visible;

    &.selected {
      background-color: $main;
      color: var(--default-background);

      .planName {
        background: none;
        -webkit-text-fill-color: initial;
      }

      .currency,
      .price {
        text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);

      }
    }

    .ribbon {
      position: absolute;
      top: -3px;
      left: 15px;
    }

    .mostPopular {
      position: absolute;
      top: -14px;
      height: 28px;
      font-size: 14px;
      color: var(--default-background);
      font-weight: 900;
      background: #ff47a0;
      width: 132px;
      display: flex;
      justify-content: center;
      font-family: "Inter";

      align-items: center;
      border-radius: 14px;
      box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
        rgb(0 0 0 / 30%) 0px 8px 16px -8px;
    }

    .attributesContainer {
      position: relative;

      span {
        font-size: 15px;
        font-weight: 500;

        &::before {
          content: "";
          display: block;
          background: url(./two-ticks.svg) no-repeat;
          width: 20px;
          height: 20px;
          position: absolute;
          left: -35px;
          top: 7px;
        }

        &:hover {
          background: linear-gradient(90deg, #259ae9, #6dd1f1);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .upgradePlan {
      color: #1b000d;

      span {
        &::before {
          background: url(./two-ticks-pink.svg) no-repeat;

        }

        &:hover {
          background: linear-gradient(to right top, #e9388a, #ffa2ce);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

    }

    .enterprise {
      font-size: 20px;
      border-radius: 8px;
      background: #e8f9fd;
      color: #53bdeb;
      text-transform: capitalize;
      transition: transform .1s, box-shadow .1s;
      font-family: "Poppins", "Assistant", sans-serif;
      box-shadow: 2px 6px 24px rgba(83, 189, 235, 0.5);
      font-weight: 700;
      padding: 10px 24px;

      &:hover {
        background: #cdf3fc;
        transform: scale(1.05);
        box-shadow: 0 13px 27px -5px rgba(50, 50, 93, .15), 0 8px 16px -8px rgba(0, 0, 0, .2);
      }
    }

    .planName {
      background: linear-gradient(90deg, #259ae9, #6dd1f1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 24px;
      margin: 0;
      font-weight: 500;
    }

    .price {
      font-size: 64px;
      font-weight: 800;
    }

    .currency {
      font-size: 64px;
      font-weight: 800;
    }

    .checkIcon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }

  .selectPlanButton {
    padding: 10px 12px;
  }
}

.actionsContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 1% 3%;
}

.small {
  .planListContainer {
    flex-direction: column;
    align-items: center;

    .planContainer {
      margin-bottom: 32px;
      margin-left: 0;
      margin-right: 0;
      width: 350px;

      @include mq($until: 800px) {
        width: calc(90vw - 400px);
      }

      @include mq($until: 600px) {
        width: calc(90vw - 100px);
      }
    }
  }
}