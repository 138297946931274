.taskListRoot {
  position: relative;
  /* border-left: #e6e4e4 1px solid; */
}

.taskListHorizontalScroll {
  overflow-x: scroll;
}

.taskListHorizontalScroll::-webkit-scrollbar {
  width: 1.1rem;
  height: 1.1rem;
}

.taskListHorizontalScroll::-webkit-scrollbar-corner {
  background: transparent;
}

.taskListHorizontalScroll::-webkit-scrollbar-thumb {
  /* border: 6px solid transparent; */
  background: rgba(0, 0, 0, 0.2);
  background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
  border-radius: 10px;
  background-clip: padding-box;
}

.taskListHorizontalScroll::-webkit-scrollbar-thumb:hover {
  /* border: 4px solid transparent; */
  background: rgba(0, 0, 0, 0.3);
  background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));
  background-clip: padding-box;
}

.taskListResizer {
  position: absolute;
  top: 0;
  right: -5px;
  height: 100%;
  width: 10px;
  cursor: ew-resize;
  user-select: none;
}

.taskListResizer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 4px;
  height: 100%;
  width: 2px;
  background-color: #e5e5e5;
}

.horizontalContainer {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.tableWrapper {
  position: relative;
}

.scrollToTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.scrollToBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.hidden {
  display: none;
}
