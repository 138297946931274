@import "../../common.scss";

.FormLabelText {
  font-size: 13px;
  font-weight: 500;
}

.ButtonLink {
  color: var(--pricing-button-color);
}





.ContinueWithDevider {
  position: relative;
  margin: 30px 0 20px 0;
  border-bottom: 1px solid var(--active-format-color);
}

.CenteredLabelText {
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translate(-50%);
  padding: 0 16px;
  background-color: var(--secondary-background-color);
}




