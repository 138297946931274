.ganttTable {
  display: table;
  /* border-bottom: #e6e4e4 1px solid; */
  /* border-top: #e6e4e4 1px solid; */
}

.ganttTable_Header {
  display: table-row;
  list-style: none;
}

.ganttTable_HeaderSeparator {
  border-right: 1px solid rgb(196, 196, 196);
  opacity: 1;
  margin-left: -2px;
}

.ganttTable_HeaderItem {
  position: relative;
  box-sizing: border-box;
  overflow: scroll;
  padding-left: 5px;
  text-align: center;
  padding-right: 5px;
  display: table-cell;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  margin: 0px 5px;
    opacity: 0.5;
    white-space: nowrap;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 600;
    border: 0;
    outline: none;
    color: var(--secondary-text-color);
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
}

.resizer {
  position: absolute;
  top: 0;
  right: -5px;
  height: 100%;
  width: 10px;
  cursor: ew-resize;
  user-select: none;
}
