.svgGeneralButtonFill {

}

.openMenuOptionGuestSection > p {
  text-align: center;
}

.openMenuOptionGuestSection > p > span {
  text-decoration-line: underline;
}

.openMenuOptionGuestSectionButtons {
  display: flex;
  justify-content: space-evenly;
}

.userEmail p {
  font-size: 12px;
}
.menuIcon {
  min-width: unset !important;
  margin-right: 10px;
}
.connectionIndicator {
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.QRPopupTitle {
  font-size: 48px;
  text-align: center;
  padding: 16px;
}
