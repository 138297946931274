.dropzoneContainer {
  height: 25vh;
  border: 2px dashed #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer !important;
}

.textWrap p {
  font-size: 13px;
  padding: 0;
  margin: 0;
}


@media (min-width: 1024px) {

}
@media (min-width: 1280px) {

}


