.paymenMethodContainer {
  .creditCardButton {
    background: var(--default-background);
    color: var(--secondary-text-color);
    font-weight: 600;
    font-size: 14px;
    min-width: 164px;
    border: 1px solid #deeef2;
    box-shadow: 1px 2px 1px rgba(84, 193, 255, 0.3);
    border-radius: 8px;
    margin-right: 8px;
    padding: 20px 20px;
    text-transform: capitalize;

    &.selected {
      border: 1px solid #53bdeb;

      &:hover {
        background: var(--default-background);
        border: 1px solid #53bdeb;
      }
    }

    &:hover {
      background: var(--default-background);
      border: 1px solid #deeef2;
    }

    // &:focus{
    //   border:none;
    //   outline: none;
    // }
  }
}

.featuresRecived {
  font-weight: 400;
  color: var(--secondary-text-color);
  font-size: 14px;
}

.label {
  color: var(--secondary-text-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  & svg {
    font-size: 16px;
    margin-left: 5px;
  }
}
.plantText {
  color: var(--placeholder-text);
}

.sumDetails {
  color: var(--secondary-text-color);
  font-size: 14px;
  font-weight: 500;
}

.lineThrough {
  text-decoration: line-through;
}

.rocket {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}
