.customCheckbox {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    border: 1px black solid;
    background-color: var(task-background-color);
  }
  
  .checkboxWrapper .checkboxMark {
    z-index: 40;
    width: 5px;
    height: 8px;
    margin-top: -1px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
  }
  
  .checkbox:enabled:checked {
    background-color: red !important;
  }