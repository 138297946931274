@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.popupContainer {
    width: 500px;
}

.loaderContainer {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}