// @media (min-width: 320px) {
//   .boardsPanelOpen {
//     width: unset;
//   }
// }
@media (min-width: 1024px) {
  .boardsPanelOpen {
    transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}

.wrapper {
  background-color: var(--secondary-background-color);
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  // height: 100vh;
  // overflow: hidden;
}

.tasksContainer {
  flex-grow: 2;
  overflow: hidden;
}

.boardsPanelClosed {
  background-color: #fff;
  color: unset;
  // width: 74px;
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media (max-width: 768px) {
  .boardsPanelOpen {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
    background: var(--tasks-header-background);
    position: absolute;
    left: 0;
    z-index: 5;

    .MuiIconButton {
      margin: 0;
    }
  }
}
