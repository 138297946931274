.notifications_wrapper {
    border-bottom: 1px solid #DEEEF2;
    border-radius: 8px;
    overflow: hidden;
}

.notifications_tab {
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    height: 36px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    background-color: transparent !important;
    outline: none;
    color: var(--secondary-text-color) !important;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    flex-shrink: 0;
    border-bottom: 2px lightgrey solid !important;
    border-radius: 0px !important;
    font-family: "Poppins", sans-serif !important;
}

.notifications_tabs_wrapper {
    margin-bottom: 16px;
    width: 100%;
    justify-content: space-between;
}

.notifications_tab:hover {
    opacity: 1;
    background: #daf6fc !important;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

.notification_row_wrapper {
    border-radius: 4px;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    padding: 0 24px 0 16px;
    min-height: 80px;
}

.notification_text_element {
    position: relative;
    // top: 50%;
    // transform: translateY(20%);
}

.board_name_label {
    margin: 0 !important;
    // position: absolute;
    // top: 1px;
    // left: 4px;
    font-weight: 300;
    font-style: italic;
    font-size: 14px;
    max-width: 350px; // Set the maximum width for the text
    white-space: nowrap; // Prevent text from wrapping to the next line
    overflow: hidden; // Hide overflow text
    text-overflow: ellipsis; // Show ellipsis (...) for overflow text
}