.move_to_menu_wrapper {
  display: grid;
  min-width: 162px;
  z-index: 10;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--background-default);
  box-shadow: 0px 8px 16px 0px var(--shadow-default-color);
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform-origin: top;
  animation: translated 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes translated {
  0% {
    top: 0px;
  }
  100% {
    top: -102px;
  }
}

.move_to_btn {
  color:var(--primary);
  border: 0;
  padding: 8px;
  display: flex;
  justify-content: left;
  cursor: pointer;
  background-color: var(--background-default);
  border-radius: 5px;
}

.move_to_btn:hover {
  background-color: var(--button-hovered-color);
}

.move_to_header {
  display: flex;
  align-items: flex-start;
}

.move_to_header p {
  font-weight: 600;
  flex: 1;
  align-self: center;
  padding: 0;
  margin: 0;
}

.back_btn {
  display: flex;
  align-items: center;
  color: var(--primary);
  border: 0;
  padding: 5px 8px;
  border-radius: 3px;
  background-color: var(--secondary-background-color);
}

.back_btn span {
  display: flex;
  align-items: center;
  margin-right: 3px;
}

.filter_container {
  display: flex;
  margin-top: 8px;
  border-radius: 3px;
  border: 1px solid var(--secondary-background-color);
  border-bottom-width: 2px;
}

.filter_container input {
  flex: 1;
  border: 0;
  outline: none;
 // padding: 8px;
  border-radius: 3px;
}

.filter_container button {
  border: 0;
  outline: none;
  background-color: var(--field-background-color);
}

.mapped_items {
  margin-top: 8px;
  height: 200px;
  overflow-y: auto;
}
.mapped_items::-webkit-scrollbar {
  background-color: var(--secondary-background-color);
  width: 2px;
}
.mapped_items::-webkit-scrollbar-thumb {
  background-color: var(--background-default);;
  border-radius: 10px;
}

.group_item_wrap {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 0 5px;
}
.group_item_wrap:hover {
  background-color: #ddd;
}

.group_item_wrap .group_item {
  flex: 1;
}

.accent_icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-color: var(--secondary-background-color);
  border-radius: 50%;
}

.mapped_items button {
  color:var(--primary);
  width: 100%;
  border: 0;
  display: block;
  text-align: left;
  padding: 8px;
  outline: none;
  border-radius: 3px;
  background-color: transparent;
}

.mapped_items button:hover {
  background-color: var(--button-hovered-color);
}

.loadingGroups {
  height: 200px;
  background-color: var(--background-default);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
