.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 30%;
  //border: 1px solid;
}
.contentWrapper h1 {
  padding: 0;
  margin: 0;
  font-size: 100px;
  font-weight: 800;
  color: #6dd1f1;
  
}
.contentWrapper p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.contentWrapper h1,
.contentWrapper h3,
.contentWrapper p {
  width: 60%;
  text-align: center;
}

.BackBtnWrapper {
  display: flex;
  justify-content: center;
  width: 60%;
}

.GoBackBtn {
    cursor: pointer;
  font-size: 16px;
  padding: 14px 10px;
  margin-top: 15px;
  outline: none;
  border-radius: 10px;
  border: 0;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(-90deg, #6dd1f1, #259ae9);
}
