.question {
    padding: 15px 10px 0px;
    color: rgb(51, 51, 51);
    word-break: break-word;
}

.answers {
    margin: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.answer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    padding: 5px 25px;
    min-height: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: rgb(102, 102, 102);
    flex-direction: column;
    word-break: break-word;
    background-color: #F5F6FA;
    border-radius: 5px;
}

.question_node_wrapper:hover .question_source_handle,
.question_source_handle:hover {
    border: 3px solid #FF9933 !important;
    background-color: white !important;
}

.question_node_wrapper:hover .status_source_handle,
.status_source_handle:hover {
    border: 3px solid #bb86fc !important;
    background-color: white !important;
}

.add_question_message_editor_wrapper {
    padding: 20px 0px;
}

.add_question_title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: rgb(51, 51, 51);
    margin-bottom: 12px;
    flex-wrap: wrap;
    gap: 5px;
}

.divider {
    margin-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgb(227, 227, 227);
}