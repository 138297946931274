.dateContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--secondary-text-color);
  cursor: text;
  padding: 0 5px;
  overflow: hidden;
  .clearIcon {
    display: none;
    color: #819ba8;
    position: absolute;
    right: 10%;
    cursor: pointer;
    transform: translate(50%, 10%);
    font-size: 15px;
    z-index: 3;
  }
  &:hover {
    color: var(--primary);
  }
  &:hover .clearIcon {
    display: block;
  }
  .addIcon,
  .dateIcon {
    width: 16px;
    visibility: hidden;
  }
  &:hover svg {
    visibility: visible;
  }
  .dateCell {
    display: flex;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      font-weight: 500;
      color: #819ba8;
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .dateIcon {
      padding-left: 2px;
      path {
        fill: var(--primary);
      }
    }
    .addIcon circle {
      fill: url(#SVGID_1_);
    }
    .addIcon path {
      fill: #ffff;
    }
    label {
      display: none;
    }
    input {
      border: none;
      font-size: 14px;
      padding: 0;
      text-align: center;
    }
    fieldset {
      border: none;
    }
  }
}
