.hoverVisibleWrapper:hover .wrapper {
  display: initial;
}

.wrapper {
  cursor: pointer;
  display: flex;
}

.wrapper:hover .mainPath {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, .7));
}

.mainPath {
  fill: none;
  display: block;
  stroke-width: 1.5px;
}

.clickZone {
  fill: transparent;
  stroke: transparent;
}
