.barRelationHandleWrapper:hover .barRelationHandle, .barRelationHandle.barRelationHandle_drawMode {
  opacity: 1;
}

.barRelationHandle {
  fill: #ffffff;
  stroke: #34a3eb;
  cursor: pointer;
  opacity: 0;
  transition: .3s;
}
