.container {
  padding: 15px;
}
.container h4 {
  padding: 0;
  margin: 0;
}

.buttons_wrapper {
  margin-top:40px;
  display: flex;
  justify-content: flex-end;
}

.buttons_wrapper button {
  padding: 10px;
  font-weight: 600;
  border-radius: 5px;
  width: 30%;
  float: right;
  cursor: pointer;
  text-transform: capitalize;
  border: 0;
  outline: none;
}

.cancel_button {
  color: #111;
  margin-right: 5px;
  background-color: transparent;
}

.cancel_button:hover {
  background: #cde9f2;
}

.delete_button {
  color: #fff;
  margin-right: 5px;
  background: linear-gradient(-90deg, #6dd1f1, #259ae9) !important;
}
