.modal {
  background-color: var(--secondary-background-color);
  max-width: 100%;
  width: 600px;
  border-radius: 6px;
  font-family: 'Montserrat', sans-serif !important;


  .header {
    padding-inline: 16px;
    height: 44px;
    background: var(--boards-panel-background);
    box-shadow: 0 0 6px 0 rgba(0, 55, 80, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif !important;

    .icon_and_email {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #53BDEB;
      font-weight: 600;

      img {
        height: 24px;
      }
    }

    .log_out {
      font-size: 12px;
      color: #809ba9;
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .body {
    padding: 16px;

    .plan {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px;
      background: var(--upgrade-box-plan-background);
      border-radius: 8px;
      gap: 4px;

      .plan_name {
        font-weight: 700;
        font-size: 24px;
        color: #53BDEB;
        font-family: 'Montserrat', sans-serif !important;
      }

      .features {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 24px;
        width: 100%;

        .feature {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
          line-height: normal !important;

          * {
            text-align: center;
          }

          .feature_name {
            font-size: 14px;
            font-weight: 800;
            font-family: 'Montserrat', sans-serif !important;
          }

          .feature_status {
            font-size: 12px;
            font-weight: 500;
            color: #7A98A7;
            font-family: 'Montserrat', sans-serif !important;
          }
        }
      }
    }

    .image {
      text-align: center;
      margin: 16px 0;

      img {
        width: 300px;
        max-width: 100%;
      }
    }

    .bottom {
      text-align: center;

      button.ctaButton {
        background: linear-gradient(66.67deg, #259ae9 0%, #6dd1f1 100%);
        height: 56px;
        font-family: 'Poppins', 'Inter', sans-serif;
        width: 100%;
        font-weight: 800;
        color: white;
        border-radius: 8px;
        border: 0;
        font-size: 18px;
        cursor: pointer;
      }

      p {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 4px;
        font-family: 'Montserrat', sans-serif!important;
      }

      .link {
        font-size: 13px;
        color: #7A98A7;
        background: none;
        border: none;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif!important;
      }
    }
  }

}