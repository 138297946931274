    .dropzone{
        width: 100px;
        height: 35px;
    }

    .dropzoneContent{
        position: absolute;
        left: 0;
        right:0;
        top: 0;
        bottom: 0;
        border:1px dashed grey;
    }
    .dropzoneContentNone{
        position: absolute;
        left: 0;
        right:0;
        top: 0;
        bottom: 0;
        border:none;
    }