.dateContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--empty-picker-cell);
  color: var(--secondary-text-color);
  overflow: hidden;
  border-radius: 4px;
  height: "25px";
  cursor: text;

  .clearIcon {
    display: none;
    color: #819ba8;
    position: absolute;
    right: 10%;
    cursor: pointer;
    transform: translate(50%, 10%);
    font-size: 15px;
    z-index: 3;
  }

  &:hover {
    color: var(--primary);
  }

  &:hover .clearIcon {
    display: block;
  }

  .addIcon,
  .dateIcon {
    width: 16px;
    visibility: hidden;
  }

  &:hover svg {
    visibility: visible;
  }

  .timelineCell {
    display: flex;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    margin: unset auto;

    .timelineCellDataEmpty {
      flex: 1;
      position: relative;
      margin: 4px auto;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }

    .timelineCellData {
      padding: 4px;
      display: flex;
      align-items: center;
      flex: 1;
      align-content: center;
      justify-content: flex-start;
      position: relative;
      width: 100%;
      margin: auto;
      text-overflow: ellipsis;
      font-family: 'Inter';
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      overflow: hidden;
      text-align: center;
      white-space: nowrap;

      .valueText {
        text-align: left;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline;
        box-sizing: border-box;
        width: calc(100% + 8px);
      }
    }

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dateIcon {
      padding-left: 2px;

      path {
        fill: var(--primary);
      }
    }

    .addIcon circle {
      fill: url(#SVGID_1_);
    }

    .addIcon path {
      fill: #ffff;
    }

    label {
      display: none;
    }

    input {
      border: none;
      font-size: 14px;
      padding: 0;
      text-align: center;
    }

    fieldset {
      border: none;
    }
  }

  .emptyTimeline {
    border-radius: 8px;
    margin: 5px;
    background: rgb(171, 171, 171);
  }

  .timelineCell div {}
}