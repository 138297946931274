.imgIcon {
  height: 20px;
  width: 20px;
}
.docIcons {
  height: 20px;
  width: 15px;
  display: inline-block;
}

