.columnHeader {
  display: flex;
  align-items: center;
}

.columnDescription {
}

.columnDescription h3 {
  font-size: 24px;
  padding: 0;
  margin: 0;
}

.columnDescription p {
  font-size: 14px;
  padding: 0;
  margin: 10px 0;
}

.dropzoneWrapper {
  padding: 25px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tudoImportIllustration {
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 500px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.illustrationBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 235px;
  width: 235px;
  background-color: #e8f9fd;
  border: 3px solid #fff;
  border-radius: 50%;
  // border-left-color: green;
  // border-right-color: #6c9ddf;
  // border-bottom-color: #6c9ddf;
  // border-top-color: #45821a;
  // border-radius: 50%;
  // transform: rotate(51deg)

  border-left-color: #00800042;
  border-right-color: #53bdeb52;
  border-bottom-color: #53bdeb;
  border-top-color: #45821a;
  border-radius: 50%;
  transform: rotate(45deg);
}

.excelIcon {
  position: absolute;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--boards-panel-background);
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 #ddd;
  top: 73%;
  left: 2%;
  border: 4px solid #4e7f380a;
  transform: rotate(-45deg);
}

.tudoLogo {
  position: absolute;
  top: 2%;
  right: 2%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-45deg);
}

.learnMore {
  margin-top: 10px;
  text-align: center;
}

.learnMore h4 {
  font-size: 15px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.learnMore p {
  font-size: 13px;
  padding: 0;
  margin: 10px 0;
}

.fileToImport {
  margin: 123px 0;
  display: grid;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.fileToImport p {
  font-size: 13px;
  flex-basis: 65%;
  padding: 0;
  margin: 0;
}

.importFile {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  justify-self: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
  color: #fff;
  background-color: #53bdeb;
}

.importFile button {
  color: #fff;
  margin-left: 4px;
  border: 0;
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
  background-color: transparent;
}

.nextBtnWrap {
  display: flex;
  justify-content: flex-end;
}

.nextBtnWrap {
  display: flex;
  align-self: flex-end;
}

.AddAndDoneBtnWrap {  //Wrap for buttons in Manage Columns menu similar to .nextBtnWrap
  display: flex;
  justify-content: space-around;
}

.AddAndDoneBtnWrap .backBtn,
.AddAndDoneBtnWrap .nextBtn,
.nextBtnWrap .backBtn,
.nextBtnWrap .nextBtn {
  font-size: 16px;
  font-weight: 500;
  padding: 12px 20px;
  border: 0;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  background-color: #53bdeb;
}

.AddAndDoneBtnWrap .backBtn,
.nextBtnWrap .backBtn {
  color: unset;

  margin-right: 10px;
  transition: 0.2s;
  background-color: #dddddd1d;
}

.AddAndDoneBtnWrap .backBtn{ 
  //Resets the margin received from .AddAndDoneBtnWrap .backBtn,.nextBtnWrap .backBtn
  margin: 0px;
}

.AddAndDoneBtnWrap .backBtn:hover,
.nextBtnWrap .backBtn:hover {
  color: #a7c3e7;
  background-color: #dddddd4a;
}

.nextBtn:disabled {
  color: #a3b4cb !important;
  background-color: #dddddd4a !important;
}

.selectColumnWrapper {
  margin-top: 10px;
}

.selectColumnWrapper select,
.card select {
  font-size: 15px;
  width: 60%;
  outline: none;
  color: #676879;
  border: 1px solid #d0d4e4;
  padding: 10px 18px;
  border-radius: 5px;
}

.excelColumnsAndExisitngCoulumns {
  display: flex;
  justify-content: space-between;
}

.excelColumnsAndExisitngCoulumns div {
  flex: 1;
}

.colRight {
  display: flex;
  justify-content: flex-end;
  margin-inline-end: 36px;
}

.columnSelectorWrapper::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: transparent;
}

.columnSelectorWrapper::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 10px;
  background-color: #c5e9f1;
}

.cheooseHowFileIsImported {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.dropzoneCompWrap {
  margin-top: 10px;
}

.cardIllustration img {
  user-select: none;
  height: 80px;
}

.cardTypography h4 {
  padding: 0;
  margin: 0;
  line-height: 1.3;
}

.cardTypography p {
  padding: 0;
  margin: 0;
}

@media (min-width: 1024px) {
  .container {
    height: 80vh;
    width: 900px;
    display: grid;
    grid-template-columns: 62% 38%;
    border-radius: 10px;
  }


  .learnMore {
    margin-top: 10px;
    text-align: center;
  }

  .dropzoneCompWrap {
    margin-top: 10px;
  }

  .fileToImport {
    margin: 10px 0;
  }

  .columnSelectorWrapper {
    width: 100%;
    margin-top: 15px;
    height: 70%;
    overflow-y: auto;
    padding: 8px;
  }

  .cheooseHowFileIsImported .card {
    position: relative;
    flex-basis: 27%;
    min-height: 260px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer !important;
    padding: 20px;
  }

  .activeIcon {
    font-size: 27px;
    position: absolute;
    right: 7px;
    top: 6px;
    color: #ff5722;
  }

  .cardIllustration {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    background-color: #ededed6e;
    border-radius: 3px;
  }

  .cardTypography {
    padding: 10px;
    margin-top: 15px;
  }

  .cardTypography h4 {
    font-size: 17px;
  }
}

@media (min-width: 1280px) {
  .container {
    height: 80vh;
    width: 1024px;
  }


  .columnSelectorWrapper {
    overflow-y: auto;
  }

  .cheooseHowFileIsImported .card {
    height: 380px;
  }

  .cardIllustration {
    height: 160px;
  }

  .cardTypography {
    padding: 10px;
    margin-top: 30px;
  }

  .cardTypography h4 {
    font-size: 19px;
  }
}