.FormContainer {
  width: 40%;
}
.FormFooter {
  margin-top: 16px;
}

.FormLabelText {
  font-size: 13px;
  font-weight: 500;
}

.ButtonLink {
  color: #53bdea;
}

@media (max-width: 600px) {
  .FormContainer {
    width: 90%;
    margin: auto;
  }
}


@media (max-width: 1280px) {
  .FormContainer {
    width: 60%;
    margin: auto;
  }
}

