.button {
  margin: 0;
  padding: 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}
