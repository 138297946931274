.filterButtonContainer {
    &:hover {

        .button_col,
        .button_col_expender {
            opacity: 0.8;
            background: var(--button-hover);
            box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);

        }
    
    }

    .button_col_expender {
        opacity: 0.5;
        background: transparent;

        &:hover {
            opacity: 1;
            box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);

        }
    }

    .button_col {
        cursor: pointer;
        opacity: 0.5;
        background: transparent;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        align-items: center;
        height: 36px;
        padding: 0 16px;
        font-size: 14px;
        border-radius: 8px;
        font-weight: 600;
        border: 0;
        outline: none;
        color: var(--secondary-text-color);
        text-transform: capitalize;
        font-family: 'Poppins', sans-serif;


        &:hover {
            opacity: 1;
            ~.button_col_expender {
                opacity: 1;
                // background: transparent;
                }
        }
    }
    .button_col_selected{
        opacity: 0.8;
        background: #fff;
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
    }
}