
.scrollingComponent {
  pointer-events: none;
}

.rowStack:hover {

  .todoCell {
    background-color: var(--task-hover-color);
  }

  .todoCell:hover {
    background-color: var(--task-cell-hover-color);
  }

  .background {
    background: var(--task-hover-color);
  }

  .subtasks_collapse_button {
    opacity: 0.5;
  }

  .subtasks_collapse_button:hover {
    opacity: 1;
    background: var(--collapse_subtasks_button-background);
  }

  .task_row_menu_button {
    opacity: 1;
  }
}

.todoCell {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-text-color);
  font-weight: 400;
  font-size: 15px;
  height: 39px;
  font-weight: 400;
  box-sizing: border-box;
  // background-color: var(--background-default);
}

.todoCell:hover {
  background-color: var(--task-cell-hover-color);
}

.subtasks_collapse_button {
  opacity: 0;
}

.subTasksBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  // padding: 0 5px 0 4px;
  padding: 0 6px 0 5px;
  background-color: var(--background-default);
  border: 1px solid #809ba8;
  border-radius: 5px;
  margin-left: 10px;
  z-index: 1;
  font-size: 12px;
}

.collapse_subtasks_button {
  display: flex;
  border: 0;
  background-color: transparent;
  outline: none;
  font-size: 18px;
  color: var(--primary);
  cursor: pointer;
  padding-top: 5px;
}

.task_row_menu_button {
  cursor: pointer;
  opacity: 0;
}

.task_row_menu_button:hover {
  opacity: 1;
}

.task_row_menu_button_in_kanban {
  opacity: 0.5;
}

.add_button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: #fff;
  padding: 3px 3px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #8a9aa7;
}

.background {
  background: var(--task-background-color);
}
.task_row:hover .background {
  background: var(--task-hover-color);
}