.convsBtn {
  position: relative;
  font-size: 23px;
  color: #b7b7b7;
  transition: 0.2s;
  border: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  margin: 0 auto;
}

.convsBtn:hover,
.personIcon:hover {
  color: #6d6c6c;
}

.convsBtnBadge {
  position: absolute;
  top: -6px;
  right: -2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #53bdeb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  padding: 2px;
  font-size: 9px;
  pointer-events: none;
}
