.dateContainer {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--secondary-text-color);
    cursor: text;
    // padding: 0 5px;
    overflow: hidden;
  
    .clearIcon {
      display: block;
      color: #819ba8;
      // position: absolute;
      right: 10%;
      cursor: pointer;
      transform: translate(50%, 10%);
      font-size: 15px;
      z-index: 3;
    }
  
    &:hover {
      color: var(--primary);
    }
  
    // &:hover .clearIcon {
    //   display: block;
    // }
  
    .addIcon,
    .dateIcon {
      width: 16px;
      visibility: hidden;
    }
  
    &:hover svg {
      visibility: visible;
    }
  
    .timelineCell {
      display: flex;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      margin: unset auto;
  
      .timelineCellDataEmpty {
        flex: 1;
        position: relative;
        background: rgb(196, 196, 196);
        height: 25px;
        border-radius: 13px;
        margin: 4px auto;
        color: #fff;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
      }
  
      .timelineCellData {
        font-size: 10px;
        display: flex;
        align-items: center;
        flex: 1;
        align-content: center;
        justify-content: center;
        position: relative;
        background: #323232;
        height: 25px;
        border-radius: 13px;
        margin: auto;
        color: #fff;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
      }
  
      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
  
      .dateIcon {
        padding-left: 2px;
  
        path {
          fill: var(--primary);
        }
      }
  
      .addIcon circle {
        fill: url(#SVGID_1_);
      }
  
      .addIcon path {
        fill: #ffff;
      }
  
      label {
        display: none;
      }
  
      input {
        border: none;
        font-size: 14px;
        padding: 0;
        text-align: center;
      }
  
      fieldset {
        border: none;
      }
    }
  
    .emptyTimeline {
      border-radius: 8px;
      margin: 5px;
      background: rgb(171, 171, 171);
    }
  
  }