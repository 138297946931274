.loginViewContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.loginFormContainer {
  display: flex;
  justify-content: center;
}
