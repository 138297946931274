.ratingCell {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    height: 35px;
    position: relative;
    cursor: text;
  
    .clearIcon {
      display: none;
      color: #819ba8;
      position: absolute;
      right: 0;
      cursor: pointer;
      transform: translate(50%, -45%);
      font-size: 15px;
      z-index: 3;
      top: 50%;
    }
    &:hover .clearIcon{
      display: block;
    }
  }
  