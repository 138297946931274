.new_item_wrapper {
  cursor: pointer;
  background-color: var(--default-background);
  height: 35px;
  border-bottom: 0px solid #567481;
}

.new_item_wrapper:hover {
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}

.new_sub_item_wrapper {
  display: flex;
  align-items: center;
  background-color: var(--default-background);
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 39px;
  border-bottom: 0px solid var(--border-color);
}

.new_item_input {
  border-radius: 8px;
  font-size: 14px;
  padding-block-start: 2px;
  width: fit-content;
  padding-inline-end: 0;
  border: 0;
  height: 35px;
  outline: none;
  color: var(--primary);
  font-weight: 600;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
}

.new_item_input::placeholder {
  color: var(--new-item-color);
}

.new_item_input:focus {
  width: 370px !important;
}

.outsideStyles:focus {
  width: 100%!important;
}

.new_item_wrapper:focus-within {
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}

.sub_item_arrow_container {
  width: 34px;
  // margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus_container {
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
