.headerContainer {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 20px;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    gap: 15px;

    .headerBtn {
      padding: 10px;
      font-weight: 700px;
      font-size: 16px;
      line-height: 22px;
    }
    img {
      height: 50px;
    }
    h3 {
      font-size: 30px;
      font-weight: 500;
      line-height: 38px;
      letter-spacing: 0em;
    }
  }
  .reinstallBtn {
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.03em;
    text-align: center;
    border-radius: 5px;
    height: 40px;
    max-width: 180px;
    background-color: #546eff;
    color: #fff;
  }
}
.titleContainer {
  display: flex;
  margin: 10px auto;
  max-width: 415px;

  .mainTitle {
    font-size: 38px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0em;
  }
}
.contentContainer {
  display: flex;
  justify-content: space-between;
  margin: auto;
  gap: 100px;
  max-width: 1440px;
  padding: 0px 20px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
  .blueticksMessage {
    max-width: 535px;
    position: relative;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 85px 55px;
    margin: 0 0 80px;
    max-height: 720px;
    box-sizing: border-box;
    @media screen and (max-width: 960px) {
      max-height: unset;
    }
    .imgContainer {
      position: absolute;
      top: -50px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      margin: 20px 0px;
    }
    .signature {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      div {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        color: #53bdeb;
      }
    }
  }
  .whyUninstall {
    margin: 0 0 80px;

    .itemsGroup {
      padding: 0px 15px 10px;
    }

    h6 {
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      letter-spacing: 0em;
      margin: 15px 0px;
    }
    .checkAll {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
      color: #abaec8;
      margin-bottom: 10px;
    }

    input[type="checkbox"] {
      outline: 1px solid #53bdeb;
    }
  }
  .sendBtn {
    margin: 30px 0px;
    padding: 15px;
    width: 200px;
    letter-spacing: 0.03em;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
}
