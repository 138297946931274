.avatar_wrap {
    width: 1000px;
    height: 1000px;
    border-radius: 200px;
    background-color: white;
}

.workload_view_header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 40px 12px;
    background: var(--secondary-background-color);
    z-index: 0;
    position: relative;
}

.workload_view_header .header_button {
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    height: 36px;
    padding: 0 16px;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 600;
    border: 0;
    background-color: transparent;
    outline: none;
    color: var(--secondary-text-color);
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    flex-shrink: 0;
}


.workload_view_header .header_button:hover {
    opacity: 1;
    background: var(--button-hover);
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

.settings_drawer_container {
    width: 390px;
    box-sizing: border-box;
    min-width: 0;
    height: calc(100vh - 80px);
    z-index: 10;
    position: absolute;
    right: 0;
    background: var(--secondary-background-color);
    padding: 24px;
    box-shadow: 0 22px 20px 0 var(--shadow-default-color);
    overflow-y: scroll;
    padding-bottom: 80px;
}

.settings_drawer .drawer_title {
    margin: 2px;
    font-size: 20px;
    font-weight: 800;
    font-family: "Poppins", "Assistant", sans-serif;
    margin-bottom: 24px;
    align-self: center;
}

.settings_drawer_title {
    display: flex;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Poppins';
    margin-bottom: 4px;
}

.field_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2px;
}



.edit_user_capacity {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px dashed black;
    border-radius: 9px;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
}

.edit_labels_button_wrap {
    padding: 0px 6px 4px 6px;
    margin-left: 10px;
    margin-right: 10px;
}

.edit_labels_button {
    cursor: pointer;
    padding: 4px 10px !important;
    background-color: var(--background-default);
    border-radius: 4px !important;
    margin-top: 3px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    width: 100% !important;
    border: 0 !important;
    outline: none !important;
    transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.edit_labels_button:hover {
    background-color: var(--secondary-background-color) !important;
}

.person_row{
width: 100%;
margin-bottom: 12px;
}


.person_row:hover {
    & .mock_person_delete_button {
        opacity: 1;
    }
}

.mock_person_delete_button {
    display: block;
    cursor: pointer;
    font-size: 15px;
    opacity: 0;
    flex-shrink: 0;
    width: 14px;
}