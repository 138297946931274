.drop_down_comp_wrap {

  padding: 10px;
  border-radius: 6px;

  background-color: var(--thread-menu-bg-color);
}
.drop_down {
  display: flex;
  flex-direction: column;
}

.drop_down button {
  width: 100%;
  font-size: 14px;
  padding: 8px 10px;
  background-color: transparent;
  color: var(--primary);
  border: 0;
  text-align: left;
  border-radius: 5px;
  cursor: pointer;
  display:flex;
  align-items: center;
}
.drop_down button:hover {
  background-color: var(--button-hovered-color);
}


