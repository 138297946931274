.group_name_container {
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.group_name_wrapper {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 10px 8px 8px;
  display: flex;
  align-items: center;
  color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 40px;
  box-sizing: border-box;
  font-family: "Poppins", "Assistant", sans-serif;
  left: -6px;
}


.collapse_button {
  cursor: pointer;
  font-size: 18px;
  display: none;
  align-items: center;
  color: #fff;
  border: 0;
  outline: none;
  padding: 0 3px;
  background-color: transparent;
  animation: collapse_btn 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.group_name_container:hover .collapse_button {
  display: flex;
}

.edit_group_name_button,
.delete_group_button {
  display: none;
  align-items: center;
  font-size: 18px;
  margin-left: 10px;
  border: 0;
  outline: 0;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
  color: #809ba8;
  background-color: transparent;
}

.edit_group_name_button:hover,
.delete_group_button:hover {
  background-color: #fff;
}

.delete_group_button:hover {
  color: #f73737;
}

.group_header_menu {
  cursor: pointer;
  opacity: 0;
  position: relative;
  // right: -8px;
  width: 40px;
}

.group_header_menu:hover {
  opacity: 1;
}

.todoRow:hover .group_header_menu {
  opacity: 1;
}

.group_name_container:hover .edit_group_name_button,
.group_name_container:hover .delete_group_button {
  display: unset;
}




.todoCell.text {
  flex-grow: 1;
  min-width: 231px;
  background-color: unset !important;
}

@media (min-width: 1024px) {
  .collapsed_item {
    flex-grow: 3;
    flex-basis: 6%;
  }
}

@media (min-width: 1280px) {
  .collapsed_item {
    flex-grow: unset;
    flex-basis: 6%;
  }
}






/* sort button CSS START */

.todoCell:hover .sort_flex_wrapper {
  display: flex;
}

.todoCell:hover .currentlyEditing .sort_flex_wrapper {
  display: none;
}

.sort_flex_wrapper {
  top: -18px;
  display: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0 25px;
  align-items: center;
  z-index: 4;

  .aside_btn {
    position: absolute;
    cursor: pointer;
  }

  .aside_btn:nth-child(1) {
    animation: aside_btn_left 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    left: -35px;
  }

  .aside_btn:last-child {
    animation: aside_btn_right 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    left: 55px;
  }
}

.aside_btn {
  display: none;
  flex: 1;
  border: 0;
  padding: 6px 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 1px 8px 1px rgb(51 51 51 / 16%);
}


@keyframes aside_btn_left {
  0% {
    transform: translate(10px);
  }

  100% {
    transform: translate(0px);
  }
}

@keyframes aside_btn_right {
  0% {
    transform: translate(-10px);
  }

  100% {
    transform: translate(0px);
  }
}

.aside_btn:hover {
  background-color: #53bdeb;
  color: #fff;
}

.sortButton {
  display: flex;
  font-size: 14px;
  flex-basis: 26px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: none;
  color: #9a9a9a;
  box-shadow: 0px 1px 8px 1px rgb(51 51 51 / 16%);
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: var(--default-background);
  cursor: pointer;
}

.caretsWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 20px;
  border: 0px solid;
}

.caretUp {
  flex-basis: 100%;
  position: absolute;
  top: 0px;
  color: #000;
  font-size: 12px;
}

.caretDown {
  flex-basis: 100%;
  position: absolute;
  bottom: -2px;
  color: #000;
  font-size: 12px;
}

.currentlySorted .sortButton {
  background-color: #53bdeb;
  color: #fff;
}

.currentlySorted:hover .aside_btn {
  display: flex;
}

/* sort button CSS END */