.completeDetailsBar {
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 10px;
    background-color:var(--secondary-background-color);
    margin-top:-2px;
    justify-content: space-between;
        box-shadow: 0px 1px 4px 0px var(--box-shadow-color);
    img {
        height: 50px;
    }
}

.loginDialog {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
.BellIconButton {
    font-size: 17px !important;
    flex-basis: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 4px !important;
    background-color: #645fff !important;
    color: #fff !important;
}

.authButton {
    border-radius: 2px !important;
    font-size: 12px !important;
    padding: 3px 23px !important;
    margin: 0 10px !important;
}

.closeIcon {
    font-size: 17px !important;
    justify-self: flex-end !important;
    background-color: transparent !important;
    padding: 4px !important;
    transition: 0.2s;
}
.closeIcon:hover {
    color: #dd2a2a !important;
}
