.taskListTableRow {
  position: relative;
  display: table-row;
  text-overflow: ellipsis;
}

.lighten {
  background-color: #eeeeff;
}

.cut {
  opacity: 0.3;
}

.taskListCell {
  box-sizing: border-box;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  padding:0 10px;
}

.dropAfter {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 10px;
  color: transparent;
}

.dropAfterLighten {
  color: #6666ff;
}

.dropAfter::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: currentColor;
}

.dropAfter::after {
  content: "";
  flex: 1;
  height: 3px;
  background-color: currentColor;
}
