// .loginForm {
//   width: 100%;
// }
.FormContainer {
  width: 100%;
  max-width: 400px;
}


.icon {
  color: #2e2e2e;
}

.provider_wrapper {
  margin-top: 15px;
}

.FormLabelText {
  font-size: 13px;
  font-weight: 500;
}

.ButtonLink {
  color: #53bdea;
}

// @media (max-width:1280px) {
//   .FormContainer {
//     width: 65%;
//   }
// }

// @media (min-width:320px) {
//   .FormContainer {
//     width: 90%;
//   }
// }
// @media (min-width:768px) {
//   .FormContainer {
//     width: 60%;
//   }
// }
// @media (min-width:1024px) {
//   .FormContainer {
//     width: 66%;
//   }
// }


// @media (min-width:1280px) {
//   .FormContainer {
//     width: 65%;
//   }
// }