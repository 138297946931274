.input_wrapper {
  display: flex;
  margin-bottom: 30px;
}
.input_wrapper button {
  flex: 1;
  text-align: left;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  background-color: var(--background-default);
  color: var(--text-color);
  transition: 0.2s all;
  cursor: text;
  font-size: 15px;
  font-weight: 400;
}
.input_wrapper button:hover {
  border: 1px solid #53bdeb;
}