.recurrenceContainer {
    display: flex;
    align-items: center;

    span,
    .recurrenceQuantity {
        margin-right: 5px;
    }

    span {
        flex: 1 1 auto;
    }

    .recurrenceQuantity {
        flex: 2;
    }

    .formControl {
        flex: 5;
    }

}

.recurrence {

    .every {
        display: flex;
        height: 36px;
        align-items: center;
        margin-bottom: 10px;

        &>span {
            white-space: nowrap;
            margin-right: 8px;
        }

        .interval {
            margin-right: 8px;
        }
    }

    .weekday {
        &>div {
            margin-bottom: 8px;
        }
    }

    .occurrences {
        display: flex;
        align-items: center;
    }
}


.datePicker {
    background-color: var(--search-container-background);
}

:global {
    .time-picker-dialog {
        direction: ltr;
    }
}