.taskListNameWrapper {
  display: grid;
  grid-template-columns:  auto 1fr auto;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.dragging {
  opacity: 0.5;
}

.taskListExpander {
  opacity: 0.9;
  font-size: 18px;
  user-select: none;
  cursor: pointer;
}

.taskListEmptyExpander {
  user-select: none;
  cursor: default;
}

.taskName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Inter','Assistant',sans-serif;
}
