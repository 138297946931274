// .form_header {
//     border-bottom: 1px solid var(--border-color);
//     display: flex;
//     align-items: center;
//     flex-direction: row;
//     justify-content: flex-end;
//     height: 46px;
//     padding: 0 26px;
//     background: var(--tasks-header-background);
//     // border-inline-start: 1px solid #ebeff8;
//     box-shadow: 0 0 6px 0 rgb(0 55 80 / 10%);
//     z-index: 1;
//     position: relative;
//     // margin-bottom: 30px;
// }

// .header_button {
//     cursor: pointer;
//     height: 36px;
//     padding: 0 16px;
//     font-size: 14px;
//     border-radius: 8px;
//     font-weight: 600;
//     border: 0;
//     color: var(--secondary-text-color);
//     font-family: 'Poppins', sans-serif;
//     text-transform: none;
// }

.form_wrapper {
    background-color: var(--background-default);
    width: 700px;
    border-radius: 20px;
    padding: 32px 48px;
}

.form_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 0px 20px 0px;
    width: 100%;
}

// .form_field {
//     padding-top: 10px;
//     margin-top: 10px;
// }

// .form_field:hover {
//     border: 1px dashed var(--text-color);
// }

// .form_field:hover:focus {
//     border: 1px solid #0097ff;
//     font-style: italic;
//     background-color: var(--secondary-background-color);

//   }

// .form_field:focus {
//     border: 1px solid #0097ff;
//     font-style: italic;
//     background-color: var(--secondary-background-color);
//   }

.form_cell {
    border: transparent;
    width: 100%;
    margin-block: 15px;
    margin-inline: calc(16px + 24px + 16px);
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
}

// .form_cell:hover {
//     box-shadow: 0px 0px 3px 1px var(--box-shadow-color);
// }

.form_column_cell {
    margin-inline-start: 6px;
    display: flex;
    justify-content: flex-start;
    width: 300px;
    // visibility: visible !important;
}

// .form_column_cell.inherit {
//     visibility: inherit;
//    }

.files_cell_filesContainer__odMxo 
.files_cell_addIcon__3p4uM,
.files_cell_filesContainer__odMxo 
.files_cell_filesIcon__gzs-U {
    visibility: visible;
}