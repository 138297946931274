@media (min-width: 1024px) {
  .mbMenu .container {
    display: none;
  }

  .container {
    display: flex;
    position: relative;
    width: unset;
  }

  .mbMenu .container {
    display: flex;
  }

  .lgClose {
    display: flex;
  }

  .mbClose {
    display: none;
  }
}

.asideNavigation {
  background-color: var(--background-default);
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.branding {
  width: fit-content;
  height: fit-content;
  margin-top: 10px;
}

.contentWrapper {
  position: relative;
  height: calc(100% - 80px);
}

.myBoards {
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.myBoards p {
  font-weight: 700;
  color: rgb(143, 143, 143);
}

.spacer {
  border-bottom: 1px solid;
  border-color: #d0d4e4;
  margin: 20px 20px;
}

.boardsRepeaterContainer {
  overflow-y: scroll;
  padding: 0px 5px;
  width: 100%;
}

.boardsRepeaterContainer::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: transparent;
}

.boardsRepeaterContainer::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 10px;
  background-color: #c5e9f1;
}

// WORKING..
.asideNavigation .avatar {
  justify-content: center;
  align-self: flex-end;
  flex-basis: 40px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border: 2px solid #546eff;
  cursor: pointer;
  transform: scale(1.08);
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.boardUL {
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: 0 16px;
  margin: 0;
}

// //working....
.boardUL .listItem {
  user-select: none;
  cursor: pointer;
  list-style-type: none;
  display: flex;
  align-items: center;
  color: var(--primary);
  border-radius: 8px;
  transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  border: 0;
  flex-grow: 2;
  padding: 0px 6px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  font-weight: 400;
}

.boardUL .listItem:hover,
.boardUL .listItem:active {
  background: var(--secondary-background-color);
}

.boardUL .listItem .board_icon,
.boardUL .listItem .board_chevron_icon {
  flex-basis: 20px;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.boardUL .listItem .board_name {
  flex: 1;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nested_container {
  padding: 0 10px;
  min-height: 100px;
  max-height: 300px;
  overflow: auto;
  background-color: #fff;
  border-radius: 5px;
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.scrollContainer {
  overflow: auto;
}

.scrollContainer::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  border-radius: 10px;
}

.scrollContainer::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}

.toggleSideBar:hover {
  background-color: #e8f9fd;
}

.newBoardWrapper {
  margin-top: 40px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.buttonLeftText {
  flex: 1;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  border: 0;
  height: 36px;
  padding: 0 30px;
  margin-right: 10px;
  background: #53bdeb;
  background: linear-gradient(-90deg, #6dd1f1, #259ae9);
  color: white;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.buttonLeftText:hover {
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
  background: #259ae9;
}

.asideNavigation .add_new_board_button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #53bdeb;
  background: linear-gradient(-90deg, #6dd1f1, #259ae9);
  border-radius: 15%;
}

.asideNavigation .aside_search_button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-top: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 15%;
}

.directoryBtn {
  flex-basis: 10%;
  cursor: pointer;
  border-radius: 8px;
  border: 0;
  height: 36px;
  padding: 0 20px;
  background: #c5e9f1;
  color: #003750;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.directoryBtn:hover {
  background: #daf3f9;
}

.searchBoardsInput {
  flex: 1;
  font-weight: 500;
  border-radius: 8px;
  border: 0;
  height: 36px;
  padding: 0 16px;
  margin-top: 12px;
  margin-bottom: 8px;
  outline: none;
  position: relative;
  color: #e5e5e5;
  // border: 1px solid #e5e5e5;
  background-color: var(--field-background-color);
}

.searchBoardsInput:focus {
  border: 1px solid #ffffff;
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}

// customize the nav when closed

.nav_closed .toggleSideBar {
  margin-left: 10px;
}

.nav_closed .board_icon {
  font-size: 22px !important;
}

.nav_closed .newBoardWrapper {
  // width: 30%;
  display: block !important;
}

.nav_closed .buttonLeftText {
  width: 45px;
  padding: 0;
}

.nav_closed .buttonLeftText span {
  display: none;
}

.nav_closed .directoryBtn {
  width: 45px;
  padding: 0;
  margin-top: 10px;
}

.nav_closed .searchBoardsInput {
  width: 10px;
  flex: none;
}

.nav_closed .searchBoardsInput::placeholder {
  visibility: visible;
}

.nav_closed .searchBoardsInput:focus {
  width: 190px;
  background-color: #fff;
  white-space: nowrap;
  z-index: 30;
}

.nav_closed .searchBoardsInput:focus .searchBoardsInput::placeholder {
  visibility: visible;
}

.customSearchIcon {
  pointer-events: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: 0.2s;
  // background-color: rgb(255, 255, 255);
  transform: translate(-64%, -50%);
  color: #667781;
  cursor: text;
  font-size: 20px;
}

.nav_closed .listItem {
  position: relative;
  width: 40px;
  margin-left: 6px;
}

.nav_closed .editMode {
  z-index: 30;
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}

.nav_closed .listItem .board_name {
  visibility: hidden;
  display: none;
  text-overflow: ellipsis;
  visibility: hidden;
  white-space: nowrap;
  overflow: hidden;
}

.listItem .closedNavBoardItem {
  display: none;
}

.nav_closed .listItem:hover {
  z-index: 30;
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}

.nav_closed .board_chevron_icon {
  visibility: hidden;
}

.nav_closed .listItem:hover .board_chevron_icon {
  visibility: visible;
}

.nav_closed .panel_footer {
  width: 54px;
  justify-content: center;
}

.nav_closed .avatar_wrapper {
  justify-content: center;
}

.nav_closed .avatar_wrapper p {
  display: none;
}

.nav_closed .avatar_wrapper .avatar {
  width: 35px;
  height: 35px;
}

.nav_closed .footer_menu_button {
  display: none;
}

.nav_closed .nested_container {
  padding: 0;
}

.listItemActive {
  background-color: var(--secondary-background-color);
  font-weight: 700;
}

.listItemActive > * {
  font-weight: 700;
}

.board_item_menu {
  cursor: pointer;
  right: 10px;
  visibility: hidden;
  width: 30px;
  height: 19px;
  padding: 0 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: none;
  background-color: transparent;

  svg {
    color: var(--text-color);
  }
}

.board_item_menu:hover {
  background-color: var(--board-item-menu-hover);
}

.listItem:hover .board_item_menu {
  visibility: visible !important;
}

.edtitBoardNameInput {
  border: 1px solid #53bdeb;
  border: 1px solid linear-gradient(-90deg, #6dd1f1, #259ae9);
  outline: none;
  padding: 6px;
  border-radius: 5px;
}

.panel_footer {
  position: fixed;
  width: 240px;
  padding: 0 10px;
  display: flex;
  height: 69px;
  align-items: center;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #ebeff8;
}

.avatar_wrapper {
  flex: 1;
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #53bdeb;
  border-color: linear-gradient(-90deg, #6dd1f1, #259ae9);
}

.footer_menu_button {
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
}

.panel_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: var(--boards-panel-background);
  overflow: visible;

  .panel_header {
    display: flex;
    width: 100%;
    height: 81px;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--separator-line);


    .logo {
      transition: 0.2s;
      height: 32px;
      object-fit: cover;
      object-position: left;
      cursor: pointer;
    }

    .toggle_drawer_icon {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      box-sizing: border-box;
      border-radius: 50%;
      padding-inline-end: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
      position:relative;
      background: var(--boards-panel-background);
    }

    .toggle_drawer_icon:hover {
      background: var(--toggle_drawer_icon-background);
    }
  }

  .new_board_button_container {
    padding-inline: 16px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-block: 20px 12px;
  }

  .search_input_container {
    display: flex;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 16px;

    .search_input {
      width: 100%;
      margin: 0px;
      padding: 0px 4px;
      background: var(--search-input-background);
    }
  }

  @media (max-width: 768px) {
    .listItem .board_item_menu {
      display: none;
    }
  }
}

.paper {
  background: red;
  border-right: 0;
}
