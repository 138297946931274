.label_container {
  position: relative;
  padding: 5px;
  display: grid;
  grid-template-rows: repeat(6, auto);
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: flex-start;
  border-radius: 10px;
  // background-color: var(--secondary-background-color);
}

.edit_labels_button_wrap {
  padding: 6px 6px 4px 6px;
  border-top: 1px solid #ddd;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.statusPicker {
  display: flex;
  flex-direction: column;
  width: 170px;
}

.statusPicker.openlist {
  background-color: #ffffff;
  padding: 8px;
  height: 186px;
  box-shadow: 0 8px 16px 0px gray;
  border-radius: 4px;
  position: relative;
  width: 154px;
}

.statusPicker.openlist button:not(:last-child) {
  margin-bottom: 15px;
}

.statusPicker button {
  font-weight: 600;
  border-radius: 0;
  color: white;

  &:hover {
    background-color: var(--background-default-active);
  }
}

.statusPicker .selectedStatus:hover {
  opacity: 0.7;
}

// picker styles
.mainPicker {
  padding: 0px !important;
  // padding: 10px !important;
  border-radius: 4px !important;
  background: var(--task-background-color) !important;
  box-shadow: 0 8px 16px 0px var(--box-shadow-color) !important;
}

// .mainPicker::after {
//   content: "";
//   width: 15px;
//   height: 15px;
//   /* border: 2px solid red; */
//   position: absolute;
//   top: -8px;
//   /* right: auto; */
//   left: calc(141px / 2);
//   z-index: 6;
//   transform: rotate(45deg);
//   background: var(--task-background-color) !important;
//   // box-shadow: 0 8px 16px 0px gray;
// }

.pickerBtnStyle {
  font-weight: 6;
  text-transform: capitalize;
  color: #919191;
  transition: 0.2s;
  box-shadow: none;
  outline: none;
  border:0;
}

// .pickerBtnStyle:hover {
//   color: #546fff;
//   background-color: #546eff42;
// }

.pickerBtn {
  font-weight: 500;
  width: 152px;
  margin: 5px;
  font-size: 13px;
  font-weight: 500;
  border:0;
}

.pickerBtn:hover {
  cursor: pointer;
}

.edit_labels_button {
  cursor: pointer;
  padding: 4px 10px !important;
  background-color: var(--secondary-background-color);
  border-radius: 4px !important;
  margin-top: 3px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 100% !important;
  border: 0 !important;
  outline: none !important;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.edit_labels_button:hover {
  background-color: var(--button-hovered-color) !important;
}
.arrowUp {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: 9999;
  top: -10px;
}
