.addScheduleContainer {
  position: relative;
  top: -2px;
  margin: 0;
  color: var(--icon);
}

.messageSchedulePreview {
  background-color: var(--rich-text-panel-background);
  border-radius: 5px;
  padding: 16px;
  margin: 10px 0;
}

.container {
  display: flex;
  flex-direction: column;
}

.timeControlsContainer {
  display: flex;
}

.alarmButton {
  font-size: 18px;
  color: var(--icon);
  border: 2px solid var(--icon);
  border-left: 2px solid transparent;
  border-radius: 50%;
  transform: rotate(55deg);
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  svg {
    margin: 2px 2px 2px 2px;
    transform: rotate(-55deg);
    font-size: 17px;
  }
}

.alarmButton:hover {
  color: #4fc1ef;
  border: 2px solid #4fc1ef;
  border-left: 2px solid transparent;
  transform: rotate(415deg);
}

.disabled {
  color: #c0c0c0;
}

.sendDetailsContainer {
  display: flex;
  flex-direction: column;
}

.scheduleDialog {
  max-width: 650px !important;
}
