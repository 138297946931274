.menu_wrapper {
    width: 245px;
    background-color: var(--background-default);
    border: 1px solid var(--bulk-item-row-menu-border);
    border-radius: 10px;
    box-shadow: 0 5px 12px var(--shadow-default-color);
}



.menu_list_item {
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 94%;
    padding: 6px 10px;
    margin: 8px;
    border: 0;
    outline: none;
    background-color: transparent;
    border-radius: 5px;
    color: var(--primary);
}

.menu_list_item:hover {
    background: var(--button-hovered-color);
}