.container {
  width: 100vw;
}
// .__container_withDrawerOpen {
//   width: calc(100vw - 100px);
// }

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 15px;
  border-left: 1px solid var(--separator-line);
  background-color: var(--task-background-color);
}

.backgroundCol {
  user-select: none;
  height: 80px;
  flex: 1;
}
.contentWrapper {
  margin-top: 75px;
}

.recentBoardsWrapper {
  width: 100%;
}

.labelWrap {
  display: flex;
  padding: 0 4px;
  justify-content: space-between;
}

.labelTitle {
  flex: 1;
  font-size: 15px;
  font-weight: 600;
  margin: 5px 0;
}
.buttonLink {
  cursor: pointer;
  color: #869ca8;
  text-align: right;
  flex-basis: 15%;
  font-size: 15px;
  font-weight: 600;
  margin: 5px 0;
}

.buttonLink:hover {
  text-decoration: underline;
}
.mappedRecentBoards {
  padding: 60px 24px 60px 24px;
  height: 147px;
  border-radius: 8px;
  background-color: var(--task-background-color);
  overflow: hidden;
}
// .container .mappedRecentBoards {
//   width: calc(100vw - 415px);
// }
// .__container_withDrawerOpen .mappedRecentBoards {
//   width: calc(100vw - 198px);
// }
.seeAll .mappedRecentBoards {
  justify-content: center !important;
  flex-wrap: wrap !important;
  height: auto !important;
  overflow: unset !important;
}

.boardItem {
  user-select: none;
  cursor: pointer;
  font-size: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;
  //margin-right: 32px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  padding:24px 0;
}

// .seeAll .boardItem {
//   margin-top: 32px;
// }

.boardItemWrap {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width:100%;
}

.boardIcon {
  position: absolute;
  left: 50%;
  top: 80%;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  border-radius: 50%;
  background-color: var(--task-background-color);
  border: 1px solid var(--border-color);
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -172%);
}

.boardItemWrap h3 {
  text-align: center;
  flex-basis: 60%;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  padding: 0;
  margin: 0;
}
.boardItemWrap p {
  color: #869ca8;
  flex-basis: 60%;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.notificationsWrapper {
  margin-top: 45px;
}

.mappedNotifications {
}

.notificationItem {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0px 24px 0px 16px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--task-background-color);
}

.iconCol {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #869ca8;
  height: 28px;
  width: 28px;
  background-color: var(--task-background-color);
}
.userAvatarCol {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  margin: 4px;
  background-color: #deeef2;
  border-radius: 50%;
}
.titleCol {
  cursor: pointer;
  flex: 1;
  font-size: 14px;
}
.dateCol {
  height: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #869ca8;
}
.dateClocIcon {
  margin: -2px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notificationItem:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.notificationItem:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
