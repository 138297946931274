.dropDownContainer {
  padding: 5% 10px;
  max-width: 280px;
  min-width:280px;
  .tagsCont {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 8px;

  }
  .labelsContainer {
    display: flex;
    align-items: center;
    .labelTag {
      background: #e5f4ff;
      color: #0073ea;
      border-radius: 8px;
      padding: 0 5px;
      min-width: 30px;
      justify-content: center;
      font-size: 12px;
      margin: 3px 5px;
      max-width:150px;
      .removeButton {
        display: flex;
        align-self: center;
        cursor: pointer;
        padding-left:2px
      }
    }
  }
  .inputContainer {
    display: flex;
  }
  // input {
  //   margin: 5% 0;
  //   outline: none;
  //   border: 1px solid var(--search-input-border);
  //   border-radius: 6px;
  //   padding: 0px 12px;
  //   height: 28px;
  //   font-family: 'Poppins', 'Assistant', sans-serif;
  //   outline: none;
  // }
  .editLabelCont {
    display: flex;
    button {
      width: 100%;
      margin-top: 5%;
      padding: 4%;
      text-transform: initial;
    }
  }
  .addLabelCont {
    button {
      width: 100%;
      margin: 2% 0;
      padding: 3% 15px;
      border-radius: 4px;
      text-transform: initial;
      background: #1a81c7;
      color: #fff;
    }
    .disabled{
      background: #d7d7d7;
      color: #b6b6b6;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
