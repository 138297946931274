.loginForm {
  display: flex;
  flex-direction: column;
}

.GroupedInputs {
  gap: 15px;
  display: flex;
}

.icon {
  color: #2e2e2e;
}


.provider_wrapper {
  margin-top: 15px;
}


.FormLabelText {
  font-size: 13px;
  font-weight: 500;
}

.ButtonLink {
  color: #53bdea;
}

.termsContainer {
  align-items: center;

  cursor: pointer;
  display: flex;

  a {
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
  }
}


.animatedStatus {
  height:15px;
  //border:1px solid;
 // flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  transition: .3s all;
  animation: status 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}


@keyframes status {
  0%{
    transform: translateY(10px);
  }
  100%{
    transform: translateY(0px);
  }
}


