.menuOption {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  cursor: pointer;
  padding: 5px;
  text-align: center;
}

.menuOption:hover {
  background-color: #eeeeee;
}

.icon {
  width: 30px;
}

.label {
  flex: 1;
}
