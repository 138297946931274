.accent_picker_wrapper {
    // position: absolute;
    // top: 40px;
    // z-index: 10;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-content: flex-start;
   // width: 190px;
    background-color: var(--secondary-background-color);
   // border: 2px solid var(--border-color-two);
    padding: 10px;
    box-shadow: 0px 6px 5px 1px var(--shadow-default-color);
    border-radius:8px

}

.accent_box {
    cursor: pointer;
    margin: 5px;
    height: 30px;
    border-radius: 5px;
    transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.accent_box:hover {
    transform: scale(1.1);
}