.filesMenu {
  font-size: 12px;
  .fileContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 4px 10px;
    &:hover{
      background:#dbdbdb;
    }
    .fileName {
      width: auto;
      max-width:50%;
      display: flex;
      align-items: center;
      cursor: pointer;
      span{
        margin-left:5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .actions{
      display:flex;
    }
  }
}
