.invoiceTable {
    td {
        padding: 5px;
        border: none;
        font-family: sans-serif;
    }

    .headerRow {
        td {
            font-weight: bold;
            background-color: #E0E4E5;
            border-bottom: 1px solid rgba(224, 224, 224, 1);
        }
    }

    .invoiceDetailsSection {
        td {
            vertical-align: top;
        }
    }
}