.message_icon {
  font-size: 22px;
  color: #ffff;
  border: 1px solid transparent;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  background: linear-gradient(-135deg, #6dd1f1, #259ae9);
  box-shadow: 0 2px 2.5px rgba(var(--shadow-rgb), 0.13);

  svg {
    color: #ffff;
  }
}

.message_icon:hover {
  background-color: linear-gradient(-135deg, rgba(109, 209, 241, 0.7), rgba(37, 154, 233, 0.7));
}

.timestamp div {
  padding: 1px 10px;
}

.message-out {
  padding-right: 5% !important;
  margin-bottom: 1%;
}

.timestamp {
  display: flex;
  justify-content: flex-start;
  font-size: 11px;
  margin-top: 10px;
}

.timestamp div:nth-child(1) {
  flex-basis: 5%;
}

.timestamp div:last-child {

  border-left: 1px solid #aaa
}

.placeholderWrap {
  width: 8rem;
}

.messageBoxContainer {
  border-radius: 7.5px;
  padding: 6px 9px 8px 7px;
  background-color: var(--wa-message-bg);
  box-shadow: 0 2px 2.5px rgba(var(--shadow-rgb), 0.13);
  font-size: 14.2px;
  color: rgba(241, 241, 242, 0.95);
  min-width: 250px;
  max-width: 300px;
}

.editIcon {
  font-size: 14px;
  cursor: pointer;
}

.messageContainer {
  overflow-wrap: break-word;
  hyphens: auto;
  padding: 0 10px;
  color: var(--primary);
  white-space: pre-wrap;
}

.timestamp {
  font-size: 11px;
  margin-top: 10px;
  color: var(--wa-message-timestamp);
}

.messageActionContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 5px;
}

.flexRow {
  display: flex;
}