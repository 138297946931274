.addLink {
    padding: 15px 20px;
    display: flex;
    flex-direction: column;

    label {
        padding-bottom: 5px;
        padding-top: 10px;
        color: #000;
        font-size: 14px;
    }

    input {
        padding: 10px;
        border: 1px solid lightgrey;
        outline: none;
    }

    Button {
        margin-top: 10px;
        padding: 0;
        border: 1px solid;
    }
}