.menu_wrapper {
  width: 245px;
  background-color: var(--boards-panel-background);
  border: 1px solid  var(--background-default);
  border-radius: 10px;
  box-shadow: 0 5px 12px var(--shadow-default-color);

  * {
    font-family: "Inter", sans-serif;
  }
}

.menu_head {
  border-bottom: 1px solid var(--border-color);
}

.menu_list_item {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 94%;
  padding: 6px 10px;
  margin: 8px;
  border: 0;
  outline: none;
  background-color: transparent;
  border-radius: 5px;
  color: var(--primary);
}

.menu_list_item:hover {
  background: var(--secondary-background-color);
}

// .menu_footer {
//   border-bottom: 1px solid var(--border-color);
// }
