.priorityPicker {
  display: flex;
  flex-direction: column;
  width: 170px;
}

.priorityPicker.openlist {
  background-color: #ffffff;
  padding: 8px;
  height: 186px;
  box-shadow: 0 8px 16px 0px gray;
  border-radius: 4px;
  position: relative;
  width: 154px;
}

.priorityPicker.openlist button:not(:last-child) {
  margin-bottom: 15px;
}

.priorityPicker .selectedStatus:hover {
  opacity: 0.7;
}

.pickerButton {
  cursor: pointer;
  font-weight: 600 !important;
  box-shadow: none !important;
  border: 0;
  outline: none;
}

.pickerButton:hover {
  background-color: #e1dfdf;
}
.pickerBtnStyle {
  text-transform: capitalize !important;
  color: var(--unset-task-value-color) !important;
  transition: 0.2s !important;
}

.pickerBtnStyle:hover,
.pickerButton:hover {
  opacity: 0.6 !important;
  //box-shadow: inset 0px 1px 5px 0px rgb(146, 146, 146) !important;
}

.pickerBtn {
  width: 100%;
  color: #fff !important;
}

.startConversationBtn {
  width: 9px;
  height: 29px;
  background-color: #13988a21;
  color: #f0f8ff00 !important;
  content: "";
  position: absolute;
  right: 0;
  top: 3px;
  z-index: 5;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.startConversationBtn:hover {
  width: 30px;
  background-color: #13988a !important;
  color: #fff !important;
}

.pickerBtn:hover.startConversationBtn {
  display: block;
}
