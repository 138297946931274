.virtuoso_wrapper::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

.virtuoso_wrapper::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
}
// .virtuoso_wrapper{
//   user-select:none
// }
:focus-visible{
  outline: none;
}