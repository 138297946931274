.menu_wrapper {
  width: 245px;
  min-height: 200px;
  background-color: var(--background-default);
  border: 1px solid var(--background-default);
  border-radius: 10px;
  box-shadow: 0 5px 12px var(--shadow-default-color);
}

.menu_head {
  border-bottom: 1px solid var(--border-color);
}

.menu_list_item {
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 94%;
  padding: 6px 10px;
  margin: 8px;
  border: 0;
  outline: none;
  background-color: transparent;
  border-radius: 5px;
  color: var(--primary);
}

.menu_list_item:hover {
  background: var(--button-hovered-color);
}

.menu_footer {
  border-top: 1px solid var(--border-color);
}

.move_to_wrapper {
  position: relative;
}

.move_to_wrapper:hover .move_to_menu {
  display: block;
}

.move_to_wrapper:hover .menu_list_item {
  background: var(--button-hovered-color);
}

.menu_component_wrap {
  position: absolute;
  top: 0;
  right: -108px;
  z-index: 30;
}

.move_to_menu {
  display: none;
  position: absolute;
  top: 0;
  right: -108px;
  z-index: 30;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--background-default);
  box-shadow: 0px 8px 16px 0px var(--shadow-default-color);
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform-origin: top;
  animation: translated 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.move_to_menu button {
  color: var(--primary);
  display: block;
  border: 0;
  padding: 8px;
  justify-content: left;
  cursor: pointer;
  background-color: var(--background-default);
  border-radius: 5px;
}

.move_to_menu button:hover {
  background-color: var(--button-hovered-color);
}

.convert_task_to_wrapper {
  position: relative;
}
.select_parent_task_wrapper {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 30;
}

.convert_task_to_wrapper:hover .select_parent_task_wrapper {
  display: block;
}
