
.inputContainer {
  display: flex;
  padding:0 16px;
  input {
    width: 100%;
    // margin: 5% auto;
    // outline: none;
    // border: 1px solid #cbd0d1;
    // border-radius: 4px;
    // padding: 4% 10px;
  }
  .search_input {
    background: var(--search-input-background) !important;
    border-radius: 4px;
  }
}

.edit_labels_button_wrap {
  padding: 6px 6px 4px 6px;
  border-top: 1px solid #ddd;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.edit_labels_button {
  cursor: pointer;
  padding: 4px 10px !important;
  background-color: var(--secondary-background-color);
  border-radius: 4px !important;
  margin-top: 3px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 100% !important;
  border: 0 !important;
  outline: none !important;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.edit_labels_button:hover {
  background-color: var(--button-hovered-color) !important;
}