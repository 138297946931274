
.signup_with_provider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding:12px 10px;
    margin: 8px 0;
    border-radius: 5px;
    background-color: var(--google-sign-in);
    color: #6d758d;
    border: 1px solid var(--border-color-two);
    outline: none;
    transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.signup_with_provider:hover {
    background-color: var(--arrow-bg-color);
}

.providerIcon {
    margin: 0 10px;
}

.chevronRightIcon {

    position: absolute;
    right: 10px;
}