.mediaManager {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background:var(--default-background);
  .showFiles {
    display: flex;
    span {
      cursor: pointer;
      padding: 4px;
    }
  }
  .docViewer {
    height: 68vh;
    width: 80%;
    margin: 0 auto;
    background:var(--background-default);
    background-color:var(--background-default)!important;

  }
  #proxy-renderer{
    background-color:var(--background-default)!important;

  }

  .mediaManagerBody {
    position: relative;
    height: calc(100% - 88px);
    display: flex;
    justify-content: center;
    // background: aliceblue;
    .mediaSideBar {
      width: 5%;
      padding: 2% 1%;
      font-size: 14px;
      text-align: center;
      border-left: 1px solid #d0d4e4;
      .versionsSelected {
        background: #def7ff;
        border-radius: 8px;
      }
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        font-size: 0.9vw;
      }
    }
    .docPreviewContainer {
      width: 100%;
      position: relative;
      padding: 2% 0;
    }
    .disabled {
      button {
        cursor: default;
      }
      svg {
        color: #d5d5d5;
        cursor: not-allowed;
      }
    }
    .nextArrow {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    .previousArrow {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    .actions {
      position: absolute;
      left: 50%;
      bottom: 10px;
      transform: translateX(-50%);
      background-color: var(--background-default);
      border-radius: 10px;
      padding: 10px;
      box-shadow: 0px 1px 8px 0px rgb(147 147 147 / 75%);
      z-index: 10;
    }
    .versionsWrapper {
      padding: 2% 0;
      width: 17%;
      margin-right: 2%;
    }
    .editWrapper{
      background:#E8F9FD;
      padding:2% 0;
      border-left: 1px solid #d0d4e4;
      width:20%;

      ::-webkit-scrollbar {
        width: 3px;
      }
      ::-webkit-scrollbar-thumb {
        background: #d0d4e4;
        border-radius: 5px;
      }
    }
    .versionsColumn {
      display: flex;
      flex-direction: column-reverse;
      align-self: flex-start;
      width: 100%;
    }
    .fileContainer {
      font-size: 12px;
      position: relative;
      border: 1px solid #c1c1c1;
      border-radius: 5px;
      padding: 10px;
      margin: 4% 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      img {
        margin-right: 5px;
        width: 45px;
        height: 45px;
      }
      .versionDetails {
        width: 80%;
        .fileNameContainer {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          line-height: 30px;
        }

        .fileName {
          width: 70%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .versionMenu {
          display: flex;
          font-size: 15px;
        }
        .versionSquare {
          position: absolute;
          right: 5px;
          bottom: 5px;
          border: 1px solid #eaeaea;
          padding: 2px;
          border-radius: 4px;
          color: black;
          font-size: 10px;
        }
        .currentVersion {
          border-color: #259ae9;
          color: #259ae9;
        }
      }
    }
    .versionSelected {
      border-color: #259ae9 !important;
    }
    .menuPopper {
      font-size: 12px;
    }
  }
  .mediaManagerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #d0d4e4;
  }
}
