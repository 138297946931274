// @media (min-width: 320px) {
//     .container {
//         height: 100vh;
//         display: flex;
//         align-items: center;
//     }

//     .branding {
//         display: flex;
//         align-items: center;
//         margin-left: 16px;
//     }

//     .branding p {
//         padding: 0;
//         margin: 0 0 0 10px;
//         font-weight: bolder;
//     }

//     .card {
//         width: 96%;
//         margin: auto;
//         box-shadow: 0px 1px 3px 0px #ccc;
//     }

//     .header {
      
//         padding: 14px;
//     }

//     .contentWrapper {
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: center;
//     }

//     .contentWrapper h2 {
//         text-align: center;
//         font-size: 24px;
//     }

//     .textWrap {
//         flex-basis: 100%;
//     }
//     .illustratorContaner {
//         flex-basis: 100%;
//         display: grid;
//         grid-template-columns: auto;
//         justify-content: center;
//     }

//     .illustratorContaner img {
//         height: 224px;
//     }
// }

@media (min-width: 768px) {
    .container {
        height: 90vh;

        display: flex;
        align-items: center;
    }
    .card {
        width: 76%;
        margin: auto;
    }

    .illustratorContaner img {
        height: 356px;
    }

    .contentWrapper h2 {
        text-align: center;
        font-size: 24px;
    }
}
@media (min-width: 1024px) {
    .container {
        height: 90vh;

        display: flex;
        align-items: center;
    }

    .illustratorContaner img {
        height: 356px;
    }
    .card {
        width: 76%;
        margin: auto;
    }
    .contentWrapper h2 {
        text-align: center;
        font-size: 24px;
    }
}
@media (min-width: 1124px) {
    .container {
        height: 90vh;
        width: 40%;
        margin: auto;
        display: flex;
        align-items: center;
    }

    .illustratorContaner img {
        height: 356px;
    }

    .contentWrapper h2 {
        text-align: center;
        font-size: 1.3em;
    }
}
