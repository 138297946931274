.headerContainer {
  height: 80px;
  padding: 0 26px;
  background: var(--tasks-header-background);
  // border-inline-start: 1px solid #ebeff8;
  box-shadow: 0 0 6px 0 rgb(0 55 80 / 10%);
  z-index: 1;
  position: relative;
}

.MobileHeaderContent {
  display: none;
}

.sales_and_support_column h2 {
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.view_button_container .view_button {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  height: 36px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 600;
  border: 0;
  background-color: transparent;
  outline: none;
  color: var(--secondary-text-color);
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}

.view_button_container:hover {
  .view_button {
    opacity: 1 !important;
    background: var(--button-hover);
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
  }
}

.view_button_container .view_button_menu {
  background: linear-gradient(to left,
      var(--button-hover),
      var(--button-hover),
      var(--button-hover),
      transparent) !important;
}

.view_button_container:hover .view_button_menu {
  opacity: 1 !important;
}

.mobileMenuBtn {
  font-size: 20px;
  border: 0;
  outline: none;
  position: absolute;
  left: 20px;
  top: 50%;
  background-color: transparent;
  transform: translate(-40%, -40%);
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 25px;
  padding: 0 10px;
  background-color: var(--default-background);
  border-bottom: 1px solid var(--border-color);
}

.header_clolumn_left {
  flex: 1;
  border: 0px solid #ddd;
}

.sales_and_support_column {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sales_and_support_column span {
  font-size: 30px;
}

.header_column_right {
  flex-basis: 25%;
  display: flex;
  border: 0px solid #ddd;
}

.button {
  white-space: nowrap;
  height: 36px;
  padding: 0 16px;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 600;
  border: 0;
  outline: none;
  color: var(--primary);
  background-color: var(--secondary-background-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-inline-end: 10px;
  font-family: "Poppins", sans-serif;
}

.button:hover {
  // background: var(--button-hovered-color);
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}

.button span {
  margin-inline-start: 8px;
}

.members {
  display: flex;
  margin-inline-end: 10px;
}

.member {
  flex-basis: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;

}

.headerButton {
  font-weight: 500 !important;
  background-color: var(--button-background-color) !important;
  color: var(--text-color) !important;
  text-transform: capitalize !important;
  display: flex !important;
  align-items: center !important;
  padding: 7px 16px;
  border-radius: 29px !important;
  box-shadow: none !important;
  transition: 0.2s all;

  svg {
    font-size: 20px;
  }
}

.headerButton:hover {
  color: #4fc1ef !important;
  // background-color: var(--button-hovered-color) !important;
}

.add_button {
  transition: 0.1s all;
}

.with_shadow_on_hover {
  transition: 0.1s;
}

.with_shadow_on_hover:hover {
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}


.add_button:hover {
  background: var(--button-hover);
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
  color: #53bdeb;
}

@media (max-width: 768px) {
  .headerContainer {
    padding-left: 50px;
  }
}

.menu_list_item {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  width: 94%;
  border: 0;
  outline: none;
  background-color: transparent;
  border-radius: 5px;
  color: var(--primary);
  align-items: center;
}


.search_input_container {
  display: flex;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 16px;

  .search_input {
    width: 100%;
    margin: 0px;
    padding: 0px 4px;
    background: var(--search-input-background);
  }
}

.redCircle {
  position: absolute;
  top: 0;
  right: 17px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15px;
  height: 19px;
  font-weight: 600;
  padding: 0 5px;
  font-size: 13px; // Adjust the font size as needed
  transform: translate(50%, -50%); // To position the circle nicely on the top-right corner
}