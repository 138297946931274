.task_list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: var(--background-default);
}

.task_list::-webkit-scrollbar-thumb {
  background-color: #eee;
}


.kanbanScroller::-webkit-scrollbar {
  width: 4px;
}

.kanbanScroller::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  border-radius: 10px;
}