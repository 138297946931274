.container {
  padding:24px;
}

.exportHeader {
  font-weight: 600;
  display: flex;
  align-items: center;
}


h3 {
  margin: 32px 0;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.export_button {
  font-weight: 600 !important;
  border-radius: 5px !important;
  width: 30% !important;
  color: #fff !important;
  text-transform: capitalize !important;
  background: linear-gradient(-90deg, #6dd1f1, #259ae9) !important;
}
