.scrollContainer {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    gap:4px;
    // padding: 0 5px;
}


.scrollContainerX {
    overflow-x: auto;
    overflow-y: visible;
    cursor: default;
}
.scrollContainerX::-webkit-scrollbar {
    height: 6px;
    background-color: transparent;
    border-radius: 10px;
}
.scrollContainerX::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 10px;
}


.scrollContainer::-webkit-scrollbar {
    width: 4px; 
    transition: opacity 0.2s; /* Add transition for smooth fade in */

}

.scrollContainer:hover::-webkit-scrollbar {
    background-color: transparent;
    border-radius: 10px;
}


.scrollContainer::-webkit-scrollbar-thumb {
    opacity: 0;
}
.scrollContainer:hover::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 10px;
    opacity: 1;
}

.mainScrollContainer::-webkit-scrollbar {
    height: 12px;
    opacity: 0;
}

.mainScrollContainer:hover::-webkit-scrollbar {
    background-color: transparent;
    border-radius: 10px;
    opacity: 1;
}
.mainScrollContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    opacity: 0;
}
.mainScrollContainer:hover::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 10px;

    opacity: 1;

}
