.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px !important;
}

.search {
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: 20px;
  margin-left: 20px;
  padding: 0 10px;
}

.row {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid var(--import-border);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  padding: 0;
}

.flexContainer {
  display: flex;
  align-items: center;
  box-sizing: "border-box";
}

.tableRow {
  cursor: pointer;
}

.tableRowHover {
  &:hover {
    background-color: grey;
  }
}
.tableCell {
  flex: 1;
  padding: 0 5px !important;
  display: flex !important;
  align-items: center;
}

:global(.ReactVirtualized__Table__rowColumn) {
  vertical-align: middle;
}

.selectedIndication {
  font-size: 14px;
  margin-right: 20px;
}

.importedActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editableField {
  background: "red";

  &::placeholder {
    font-style: italic;
  }
}
