.y_handle {
    background-color: green !important;
    position: absolute;
    top: 35% !important;
    right: -3px !important;
    z-index: 3;
}

.n_handle {
    background-color: red !important;
    position: absolute;
    margin-top: 5% !important;
    right: -3px !important;
    z-index: 3;
}

.condition_node_wrapper:hover~.y_handle,
.y_handle:hover {
    border: 3px solid green !important;
    background-color: white !important;
}

.condition_node_wrapper:hover~.n_handle,
.n_handle:hover {
    border: 3px solid red !important;
    background-color: white !important;
}


.add_google_sheets_dialog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(227, 227, 227);
}

.divider {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(227, 227, 227);
}

.title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 12px;
    flex-wrap: wrap;
    gap: 5px;
}
