.filesContainer{
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color:#819ba8;
    cursor: pointer;
    padding: 0 5px;
    .clearIcon {
        display: none;
        color: #819ba8;
        position: absolute;
        right: 10%;
        cursor: pointer;
        transform: translate(50%, 10%);
        font-size: 15px;
        z-index: 3;
    }
    .extraFiles{
        height: 20px;
        width: 20px;
        background: var(--primary);
        display: flex;
        color: white;
        font-size: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-top: 2px;
        position: absolute;
        right: 1px;
        top: 8px;
        .cirlce{
            fill: url(#SVGID_1_);
        }
    }
    &:hover{
        color:var(----primary);
    }
    &:hover .clearIcon {
        display: block;
    }
    .addIcon,
    .filesIcon {
        width: 16px;
        visibility: hidden;
    }
    .addWithFiles{
        position: absolute;
        left: 8%;
        top: 50%;
        transform: translate(-50%, -40%);
        width: 13px;
        height: 13px;
    }
    &:hover svg {
        visibility: visible;
    }

    .addIcon circle {
        fill: url(#SVGID_1_);
    }
    .addIcon path {
        fill: #ffff;
    }
    .filesCell {
        display: flex;
        position: relative;
        .filesIcon {
            padding-left: 2px;
            path {
                fill: var(----primary);
            }
        }      
        // .addIcon circle {
        //     fill: url(#SVGID_1_);
        // }
        // .addIcon path {
        //     fill: #ffff;
        // }
        .fileIcon{
            padding:0 2px;
            display: flex;
        }
        .imgIcon{
            height:20px;
            width:20px;
        }
        .docIcons{
            height:20px;
            width:15px;
            display:block;
            img{
                width:100%;
                height: 100%;
            }
        }
    }
}

