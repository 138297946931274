.InputContainer {
  flex:1;
  // margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}
.InputContainer label {
  flex-basis: 90%;
  font-size: 13px;
  font-weight: 500;
  margin: 5px 0 10px 0;
  display: block;
}

Input {
  flex: 1;
  height: 42px;
  border: 1px solid var(--border-color-two);
  outline: none;
  padding: 0 10px;
  border-radius: 5px;
}
