.members {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-inline-end: 10px;
    width: 25%;
}

.member {
    flex-basis: 32px;
    border-radius: 50%;

}

.search_input {
    width: 100%;
    margin: 0px;
    padding: 0px 4px;
    background: var(--search-input-background) !important;
}

@media (min-width: 1024px) {
    .search_input {
        font-weight: 500;
        border-radius: 8px;
        font-size: 14px;
        border: 0;
        height: 36px;
        padding: 0 16px;
        margin: 0 10px;
        outline: none;
        width: 267px;
        max-width: 100%;
    }
}



.search_input:focus {
    box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}