.container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;

  .card {
    max-width: 600px;
    margin: auto;
    box-shadow: 0px 1px 3px 0px #ccc;
  }

  .header {
    display: flex;
    align-items: center;
    margin-left: 16px;
    padding: 20px;
  }

  .header p {
    padding: 0;
    margin: 0 0 0 10px;
    font-weight: bolder;
  }

  .body {
    padding: 30px 70px 70px 70px;
  }
  .body h2 {
    text-align: center;
    font-size: 24px;
  }
  .body img {
    margin-top: 20px;
    width: 100%;
  }
  .body p {
    text-align: center;
    font-style: italic;
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .container {
    height: unset;
    .body {
      padding: 15px;
    }
  }
}
