.hotmartButton {
  background-color: #53bdeb;
  color: white;
  min-height: 35px;
  min-width: 200px;
  max-width: 750px;
  padding: 10px 24px;

  &.white {
    background-color: #ffffff;
  }

  img {
    height: 25px;
  }
}

.blueButton {
  background: var(--billing-btn-bg);
  color: var(--pricing-button-color) !important;
  opacity: 1 !important;
  font-weight: 500 !important;
}

.pinkButton {
  background: linear-gradient(87.82deg, #f656a1 0%, #ffa3ce 100%);
  color: var(--background-default) !important;
  font-size: 20px;
  box-shadow: 2px 6px 24px 0px rgba(255, 71, 160, 0.5);
  transition: transform .1s,box-shadow .1s;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 13px 27px -5px rgba(50,50,93,.15), 0 8px 16px -8px rgba(0,0,0,.2);
  }
}

.transparentButton {
  background: none;
  color: var(--pricing-button-color) !important;
  opacity: 1 !important;
  font-weight: 500 !important;

  span {
    display: none;
  }
}