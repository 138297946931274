.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  // position: relative;
}

.extraDataPanel {
  overflow: auto;
  height: 100%;
  width: 450px;
  background: var(--extradata-background-color);
}

.extraDataPanel::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  border-radius: 10px;
}

.extraDataPanel::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}


@media only screen and (max-width: 768px) {
  .extraDataPanel {
    width:auto;
  }
}


.panelContainer {
  position: relative;
  border-left: 0px solid var(--border-color);
  background-color: #e8f9fd;
  user-select: none;
}

.topBar {
  display: grid;
  grid-template-columns: 6% auto 5% 1fr 5%;
  justify-content: space-between;
  color: #4a4a4a;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  padding: 10px;
  align-items: center;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid rgb(233, 233, 233);
}
.topBarIcons {
  transition: 0.2s;
  color: #6d758d !important;
  // margin: 4px 10px !important;
}
.topBarIcons:hover {
  background-color: var(--button-hovered-color) !important;
}

.graphIcon {
  flex-basis: 7%;
  display: flex;
  justify-content: center;
}

.inlineEditWrap {
  flex-basis: 20%;
}
.shareIcon {
  flex: 1 !important;
  font-size: 23px;
  display: flex;
  justify-content: center;
}
.deleteIcon {
  flex: 1 !important;
  display: flex;
  justify-content: center;
  justify-content: center;
  font-size: 23px;
  transition: 0.2s;
}
.deleteIcon:hover {
  color: #ff3867 !important;
  border: 1px dashed #ff3867 !important;
}

.solidLine {
  flex-basis: 75%;
  height: 2px;
  background-color: #53bdeb;
}

.emptyBoard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: var(--secondary-text-color);
  border-radius: 0px;
}

.taskXIcon {
  display: flex;
  justify-content: center;
  svg {
    font-size: 54px;
    color: #259ae9;
  }
}

.createGroup {
  text-transform: capitalize !important;
  width: 100%;
  padding: 12px 16px;
  background-color: #53bdeb !important;
  color: #fff !important;
  border-radius: 5px;
  border-bottom: 4px solid #4760f0 !important;
  outline: none;
}

.customSkeletonStyle {
  background-color: var(--secondary-background-color) !important;
}
.extraDataWrapper {
  top: 0;
  width: 399px;
  z-index: 100;
  transition: right 0.5s ease-in-out;
}

.closeExtraDataWrapper {
  right: -399px;
}

.setBoardIconPicker {
  position: absolute;
  top: -17px;
  left: 32px;
  z-index: 60;
  box-shadow: 0 5px 12px 0 rgb(0 0 0 /15%);
}


.resizeHandle {
  width: 15px;
  height: 100%;
  background-color: lightgrey;
  cursor: col-resize;
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  opacity: 0; 
  transition: opacity 0.4s;
}


.resizeHandle:hover {
  opacity: 1;
}