.mainUserContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--boards-panel-background);
  border-top: 1px solid var(--separator-line);
  padding: 16px;
  .user_container {
    display: flex;
    align-items: center;
    gap: 14px;
    
    .user_avatar {
      border-radius: 50%;
      border: 2px solid white;
      box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
      padding: 0;
      width: 46px;
      height: 46px;
      box-sizing: content-box;
    }
  }


.MuiPaper-elevation,
#basic-menu .MuiPaper-elevation {
  border-radius: 10px !important; 
  box-shadow: 0 5px 12px var(--shadow-default-color);
}



  // .smallMenuBtn {
  //   padding: 0px 0px 8px;
  //   gap: 4px;
  //   position: relative;;
  //   top: -100%;
  //   left: 2.5%; // to make it in the middle
  //   width: 95%;
  //   background: var(--background-default);
  //   box-shadow: 0px 0px 20px var(--field-border-color);
  //   border-radius: 6px;
  // }
}

.smallUserMenuContainer {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0;
  height: 100%;
  margin-block-start: -8px;
  background-color: var(--boards-panel-background);
  a {
    text-decoration: none;
  }
  .smallMenuItem {
    display: flex;
    cursor: pointer;
    margin: 4px 0;
    height: 32px !important;
    align-items: center;
    padding: 0px 16px 0 8px;
    gap: 10px;
    color: var(--secondary-text-color);
    margin-inline: 8px;
    border-radius: 6px;
    transition: 0.1s;
    img {
      width: 15px;
      height: 15px;
    }
    .userMenuLabel {
      text-transform: capitalize;
      padding: 0px;
      font-size: 14px;
      line-height: 24px;
      color: var(--secondary-text-color);
    }
  }
  .workspaceItem {
    display: flex;
    border-bottom: 1px solid var(--border-color);
    gap: 8px;
    align-items: center;
    padding: 12px;
    .workspaceLabel {
      font-size: 12px;
      font-weight: 300;
      line-height: 15px;
      letter-spacing: -0.015em;
    }
    // img {
    //   width: 24px;
    //   height: 24px;
    // }
  }
}

.smallMenuItem:hover {
  background-color: var(--secondary-background-color);
}



span.user_name {
  font-family: "Poppins", "Inter", sans-serif;
  font-weight: 700;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
  display: block;
}

span.workspace_name {
  margin-top: -2px;
  display: block;
  font-family: "Poppins", "Inter", sans-serif;
  font-weight: 600;
  cursor: pointer;
  font-size: 12px;
  color: #869ca8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
}

.board_item_menu {
  cursor: pointer;
  right: 10px;
  width: 30px;
  height: 19px;
  padding: 0 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: none;
  background-color: transparent;

  svg {
    color: var(--text-color);
  }
}

.board_item_menu:hover {
  background-color: var(--secondary-background-color);
}

.listItem:hover .board_item_menu {
  visibility: visible !important;
}

