.fixed_wrapper {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
  width: 400px;
  display: flex;
  justify-content: center;
  z-index: 10;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform-origin: bottom;
  animation: translated 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes translated {
  0% {
    top: 0px;
  }
  100% {
    top: 50px;
  }
}

.deleted_message {
  padding: 10px 16px;
  background: var(--background-default);
  border-radius: 5px;
  display: flex;
  white-space: nowrap;
  box-shadow: 0 5px 12px 0 rgb(0 0 0 /15%);
}
.deleted_message p {
  padding: 0;
  margin: 0;
  color: var(--primary);
}

// .deleted_message button {
//   cursor: pointer;
//   color: #fff;
//   flex-basis: 15%;
//   margin-left: 10px;
//   border: 0;
//   outline: none;
//   border-radius: 5px;
// }
.undoBtn {
  background: linear-gradient(-90deg, #6dd1f1, #259ae9);
  padding: 1px 6px;
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid !important;
  background-color: transparent !important;
}
