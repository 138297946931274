
.personButtonFilterContainer{
    .button_col {
        cursor: pointer;
        opacity: 0.5;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        align-items: center;
        height: 36px;
        padding: 0 16px;
        font-size: 14px;
        border-radius: 8px;
        font-weight: 600;
        border: 0;
        outline: none;
        color: var(--secondary-text-color);
        text-transform: capitalize;
        font-family: 'Poppins', sans-serif;
        &:hover{
            opacity: 1;
            background: var(--button-hover);
            box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);    }
    }
}
