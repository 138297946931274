.container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: #292f4cb3;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.image_wrapper {
  flex-basis: 40%;
  position: inherit;
}
.image_wrapper img {
  border-width: 14px;
  border-color: #fff;
  border-style: solid;
  border-radius: 3px;
}

.close_button {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 33px;
  height: 33px;
  background-color: #111;
  color: #fff;
  border: 4px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  box-shadow: 0px 1px 4px 0px #ccc;
  outline: none;
  font-size: 20px;
}
