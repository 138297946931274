.textCell {
  &:hover {
    .plusIcon {
      display: block;
    }

    .smTextIcon {
      display: block;
    }
  }

  width: 100%;
  transition: 0.2s;
  padding: 0 5px;
  height: 35px;
  position: relative;
  cursor: text;

  .addIcon {
    width: 16px;
    visibility: hidden;
  }

  &:hover svg {
    visibility: visible;
  }

  .addIcon {
    position: absolute;
    left: 40%;
    transform: translate(-40%, -50%);

    & circle {
      fill: url(#SVGID_1_);
    }

    & path {
      fill: #ffff;
    }
  }
}

.smTextIcon {
  display: none;
  position: absolute;
  left: 60%;
  font-size: 18px;
  transform: translate(-60%, -50%);
  color: lightgrey;
}

.clearIcon {
  display: none;
  position: absolute;
  right: 15px;
  bottom: 9px;
  color: #819ba8;
  cursor: pointer;
  font-size: 15px;
  z-index: 3;
  pointer-events: initial;
}

.textCell:hover .clearIcon {
  display: block;
}

.textCell div:focus-within+.textIcon {
  display: none;
}


.textCell:hover input {
  color: var(--primary);
}