.add_status_message_editor_wrapper {
    padding: 20px 0px;
}

.add_status_title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: rgb(51, 51, 51);
    margin-bottom: 12px;
    flex-wrap: wrap;
    gap: 5px;
}

.divider {
    margin-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgb(227, 227, 227);
}