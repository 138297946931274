.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--background-default);
}

.addColumnBtn {
  font-size: 14px;
  display: flex;
  align-items: center;
  border: 0;
  padding: 8px;
  margin: 0 10px;
  display: flex;
  justify-content: left;
  cursor: pointer;
  background-color: var(--background-default);
  border-radius: 5px;
  color: var(--primary);
}

.addColumnBtn:hover {
  background-color: var(--secondary-background-color);
}

.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 4px 3px;
  background-color: #eef8fc;
  border-radius: 5px;
  width: 22px;
  height: 22px;
}

.menuFooter {
  border-top: 1px solid #ebebeb;
}

.moreColumnsBtn {
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 0;
  padding: 8px;
  display: flex;
  justify-content: left;
  cursor: pointer;
  background-color: var(--background-default);
  border-radius: 5px;
  color: var(--primary);
}

.moreColumnsBtn:hover {
  background-color: var(--secondary-background-color);
}

// .popover {
//   box-shadow: 0 5px 12px var(--shadow-default-color);
//   border-radius: 5px;
//   background-color: var(--background-default);
//   // padding: 16px;
//   overflow-x: hidden;
// }