.checkboxCell {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  input[type="checkbox"] {
    cursor: pointer;
  }
}
