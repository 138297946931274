.phoneCell {
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;

  &:hover {
    .plusIcon {
      display: block;
    }

    .smPhoneIcon {
      display: block;
    }
  }

  width: 100%;
  transition: 0.2s;
  position: relative;
  cursor: text;

  .smPhoneIcon {
    display: none;
    position: absolute;
    left: 55%;
    font-size: 18px;
    transform: translate(-60%, -50%);
    color: lightgrey;
  }

  .addIcon {
    width: 16px;
    visibility: hidden;
  }

  &:hover svg {
    visibility: visible;
  }

  .addIcon {
    position: absolute;
    left: 40%;
    transform: translate(-40%, -50%);

    & circle {
      fill: url(#SVGID_1_);
    }

    & path {
      fill: #ffff;
    }
  }
}

.clearIcon {
  display: none;
  color: #819ba8;
  position: absolute;
  right: 10%;
  cursor: pointer;
  transform: translate(50%, -45%);
  font-size: 15px;
  z-index: 3;
}

.phoneCell:hover .clearIcon {
  display: block;
}

.phoneCell input:focus+.numbersIcon {
  display: none;
}

.phoneCell input:focus+.plusIcon {
  display: none;
}

.phoneCell:hover input {
  color: var(--primary);
}