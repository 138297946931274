.upload_status {
  position: absolute;
  width: 100%;
  background-color: #62626215;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 2;
}

.upload_status_card {
  height: 60px;
  width: 60px;
  background: var(--secondary-background-color);
  box-shadow: 0px 1px 3px 0px var(--border-colo);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 53px;
}



.task_name {
  font-size: 24px;
  font-weight: 800;
  padding: 0 8px;
  // margin-block-end: 24px;
}

.close_button {
  position: absolute;
  left: 10px;
  top: 5px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--button-background-color);
  color: var(--text-color);
  box-shadow: 0px 1px 3px 0px var(--box-shadow-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  transition: 0.2s;
}

.close_button:hover {
  box-shadow: 0px 1px 3px 0px var(--box-shadow-color);
}

.body {
  padding: 0 8px;
}

// .header p {
//   color: #53bdeb;
//   font-size: 1.1em;
//   font-weight: 400;
//   margin: 0;
//   padding: 0;
// }


// .body {
//   padding: 10px;
//   height: 882px;
//   overflow-y: auto;
// }

// .body::-webkit-scrollbar {
//   width: 4px;
// }
// .body::-webkit-scrollbar-thumb {
//   background-color: #ccc;
//   border-radius: 10px;
// }

.render_threads_comp {
  margin: 0px 0 !important;
}

.nav_button {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 15px;
  gap: 10px;

  background: var(--default-background);
  border: 1px solid var(--separator-line);
  border-radius: 13px;
}

.body::-webkit-scrollbar {
  width: 4px;
}
.body::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  border-radius: 10px;
}
