
.FlexContainer {
  background-color: var(--background-default);
  width: 100%;
  margin: auto;
  display: flex;
  color: var(--primary);
}

.col {
  flex-basis: 50%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  // display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-default);
}
.col:nth-child(1) {
  background-color: var(--secondary-background-color);
}

// @media (max-width: 900px) {
//   .col:nth-child(1) {
//     display: none;
//   }
//   .col:nth-child(2) {
//     flex:1;
//   }
// }