.source_handle {
    background-color: #4d4d4d !important;
    z-index: 3;
}

.source_handle:hover {
    border: 3px solid #4d4d4d !important;
    background-color: white !important;
}

.add_chatgpt_dialog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(227, 227, 227);
}

.title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 12px;
    flex-wrap: wrap;
    gap: 5px;
}

.secondary_title {
    margin-top: 10px;
    color: rgb(102, 102, 102);
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
}

.divider {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(227, 227, 227);
}

.scrollContainer {
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 10px;
}

.scrollContainer::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    border-radius: 10px;
    background-color: #e5e5e5;
}

.scrollContainer::-webkit-scrollbar-thumb {
    background-color: #acacac;
    border-radius: 10px;
}