.root {
  font-size: 16px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;

  .loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
  }

  .row {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  .price {
    margin: 25px 0;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    .priceTag {
      font-size: 24px;
    }
  }
}

.title {
  font-size: 20px;
}

.support {
  color: #69696a;
  display: flex;
  align-items: center;

  img {
    height: 30px;
    margin-right: 5px;
  }
}

.paypalSubscribe {
  margin-top: 30px;
}

.activeSubscriptionContainer {
  max-width: 400px;
  display: flex;
  align-items: center;
}

.bigTitle {
  font-size: 32px;
}
