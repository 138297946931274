.pricingContainer {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px 0;

    h1 {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin: 0;
    }

    .title {
        font-size: 24px;
    }

    .featureTable {
        max-width: 900px;
        margin-left: 30px;
    }

    .premium {
        color: rgb(0, 146, 213);
    }
}

.logo {
    height: 60px;
    margin: 15px;
}

.paymentContainer {
    background-color: rgb(226,226,226);
    display: flex;
    align-items: center;
    justify-content: center;
}

.security {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    img {
        height: 60px;
        margin-right: 20px;
    }
}
