.versionsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d0d4e4;
    border-radius: 5px;
  }
}
.versionsContainer 
.versionsColumn {
  display: flex;
  flex-direction: column-reverse;
  height: 45vh;
  overflow-y:auto;
  width: 100%;
  padding-right: 2%;
  justify-content: flex-end;
}
.fileContainer {
  font-size: 12px;
  position: relative;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 10px;
  margin: 4% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  img {
    margin-right: 5px;
    width: 45px;
    height: 45px;
  }
  .versionDetails {
    width: 80%;
    .fileNameContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      line-height: 30px;
    }

    .fileName {
      width: 70%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .versionMenu {
      display: flex;
      font-size: 15px;
    }
    .versionSquare {
      position: absolute;
      right: 5px;
      bottom: 5px;
      border: 1px solid #eaeaea;
      padding: 2px;
      border-radius: 4px;
      color: black;
      font-size: 10px;
    }
    .currentVersion {
      border-color: #259ae9;
      color: #259ae9;
    }
  }
}
.versionSelected {
  border-color: #259ae9;
}
.menuPopper {
  font-size: 12px;
}
