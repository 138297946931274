
.form {
  display: flex;
  flex-direction: column;
}



.FormFooter {
  margin-top: 16px;
}


.FormLabelText {
  font-size: 13px;
  font-weight: 500;
}

.ButtonLink {
  color: #53bdea;
}