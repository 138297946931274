.labelsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--secondary-text-color);
  cursor: pointer;
  padding: 0 5px;
  height: 100%;
  overflow: hidden;
  .label {
    background: #e5f4ff;
    color: #0073ea;
    border-radius: 8px;
    min-width: 30px;
    font-size: 12px;
    margin: 3px 2px;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    padding:0 10px;
  }
}
