.row {
  display: flex;
}

.alignCenter {
  align-items: center;
}

.accountViewContainer {
  display: flex;
  flex-direction: column;
}

.accountFormContainer {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageContainer {

  // padding: 16px 60px;
  height: 100%;

  .titleContainer {
    background: var(--background-default);
    color: var(--primary);
    padding: 0 24px;
    height: 80px;
    display: flex;
    align-items: center;

    .pageTitle {
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0em;
      margin: 0;
    }
  }

  .accountSettingBody {
    box-sizing: border-box;
    padding: 24px 56px;
    background-color: var(--secondary-background-color);
    margin: 0;

    .inputsContainer {
      max-width: 400px;
    }

  }
}

.newWorkspace {
  margin: 30px 0px;
  display: flex;

  .workspaceImgContainer {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      linear-gradient(45deg, #259ae9 0%, #6dd1f1 100%);
    align-items: center;
    justify-content: center;
    display: flex;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .workspaceName {
    font-size: 18px;
    font-weight: 900;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    background: linear-gradient(45deg, #259AE9 0%, #6DD1F1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 12px;
  }
}

.unselectedWorkspace,
.selectedWorkspace {
  z-index: 1;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.125);
  font-family: "Inter", "Assistant", sans-serif;
  font-size: 14px;
  letter-spacing: -0.015em;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  padding: 4px 6px;
  background-color: #53bdeb;
  justify-content: center;
  top: 20px;
}

.unselectedWorkspace {
  opacity: 0;
  transition: 0.1s;
}

.workspaceItemContainer:hover .unselectedWorkspace {
  opacity: 1;
}

.workspacesContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0px;
  gap: 70px 95px;
}

.workspaceItemContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", "Inter", "Assistant", sans-serif;

  .workspaceImgContainer {
    height: 150px;
    width: 150px;
    border-radius: 50%;

    .workspaceImg {
      object-fit: cover;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    }

    .workspaceImgPlaceholder {
      height: 150px;
      width: 150px;
      background: #FAFEFF;
      color: #53bdeb;
      display: block;
      border-radius: 50%;
      box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 64px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .itemName {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 12px;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.fieldName {
  color: var(--primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.inputField {
  width: 370px;
  color: var(--secondary-text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  border-radius: 8px;
  padding: 10px 16px;
  margin-top: 20px;
  border: none;
}


.editableField {
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.profileImg {
  border-radius: 75px;
  object-fit: cover;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.replaceImg {
  position: absolute;
  bottom: 10px;
  right: 0px;

  img {}

  input {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.heading {
  margin: 20px 0;
}

.notificationTableContainer {
  max-width: 852px;

  .notificationTableRow {
    display: grid;
    align-items: center;

    grid-template-columns: 3.5fr 1fr 1fr 1fr;
  }

  .notificationTableRowHeader {
    h6 {
      margin: 10px 0px;
      display: block;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      color: var(--secondary-text-color);
    }

    span {
      display: block;

      color: var(--secondary-text-color);
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
    }
  }

  .notificationTableRowNoti {
    background: var(--background-default);

    h6 {
      display: block;
      margin: 5px 0px;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      color: var(--secondary-text-color);
      padding: 10px 16px;
    }

    .emptyNoDesk {
      display: block;
    }
  }
}

.DesktopAllowContainer {
  display: flex;
  gap: 16px;
  padding: 40px 0;

  h6 {
    margin: 10px 0px;
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    color: var(--secondary-text-color);
  }
}

@media screen and (max-width: 767px) {
  .accountSettingBody {
    max-width: none;
  }
}

// Billing
.billingPageContainer {
  max-width: 700px;

  .billingCardContainer {
    padding: 16px 20px;
    background-color: var(--background-default);
    border-radius: 12px;
  }

  .billingSmallTitleCard {
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary-text-color);
    margin: 0;
  }

  .billingBlueTtl {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: var(--billing-blue-title);
    margin: 0;
  }

  .billingBlueMed {
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    color: var(--billing-blue-title);
    margin: 0;
  }

  .billingBlueSmall {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: var(--billing-blue-title);
  }

  .creditCardSection {}

  .billingCardBtn {
    cursor: pointer;
    margin: 0;
    text-decoration: none;
    border-radius: 12px;
    background-color: var(--billing-btn-bg);
    color: var(--link-text-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 6px;
    text-align: center;
    text-transform: capitalize;
  }

  .detailsCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 180px;
    width: 300px;
  }

  .invoiceHistoryCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 150px;
    flex: auto;

    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin: 0;
    }

    span {
      margin: 5;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
}

.billingOpenModal {
  padding: 24px;
  height: calc(100% + 48px);
  background-color: var(--secondary-background-color);
  //box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.bigModal {
  width: 400px;
}

.closeBillingModal {}

.modalTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: var(--secondary-text-color);
  text-align: left
}

.invoicesTableContainer {}

.invoicesTableHeaderContainer {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 2fr;
  padding: 0 12px;

  h6 {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin: 10px 0;
    color: var(--primary);
  }
}

.invoicesTableRowContainer:nth-child(0) {
  border-radius: 8px 8px 0px 0px;
}

.invoicesTableRowContainer:last-child {
  border-radius: 0px 0px 8px 8px;
}

.invoicesTableRowContainer {
  background: var(--background-default);

  border: 1px solid var(--border-color);
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr;
  padding: 12px;

  div {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;

    .cardImgCont {
      width: 40px;
      height: 24px;
      border: 1px solid var(--border-color);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;

      span {
        display: flex;
      }
    }
  }

  .invoiceStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 8.5px;
    width: 40px;
    height: 24px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
  }

  .billingCardBtn {
    cursor: pointer;
    margin: 0;
    text-decoration: none;
    border-radius: 12px;
    padding: 3px 12px 5px 12px;
    background-color: var(--billing-btn-bg);
    color: var(--billing-blue-title);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50px;
  }
}

.accountSettingBody::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

.accountSettingBody::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
}