.NewBoardButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  width: 230px;
  color: #fff;
  cursor: pointer;
  border: 0;
  outline: none;
  padding: 5px 10px;
  border-radius: 10px;
  background: linear-gradient(-90deg, #6dd1f1, #259ae9);
  font-family: "Poppins";
  svg {
    color: #fff;
  }
}

.NewBoardButton .spinner {
  font-size: 23px;
  margin-right: 6px;
}
.NewBoardButton .plusIcon {
  font-size: 23px;
  margin: 4px 6px 0 0;
}
