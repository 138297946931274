.header {
  margin-top: 0px;
  padding: 0 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--secondary-background-color);
}

.MobileHeaderContent {
  display: none;
}

.headerContainer .header .button_col {
  cursor: pointer;
  opacity: 0.5;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 600;
  border: 0;
  background-color: transparent;
  outline: none;
  color: var(--secondary-text-color);
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  flex-shrink: 0;

}



// .headerContainer .header .button_col span {
//   margin-left: 5px;
// }

.headerContainer .header .button_col:hover {
  opacity: 1;
  background: var(--button-hover);
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width:768px) {
  .header {
    padding: 40px 0 0 30px;
  }
}


.popover {
  max-height: 400px;
  z-index: 100;
  margin: 2;
  box-shadow: 0 5px 12px var(--shadow-default-color);
  border-radius: 5px;
  background-color: var(--background-default);
  padding: 16px;
  overflow-x: hidden;
  text-align: center;
  max-width: 180px;
}

.popover::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  border-radius: 10px;
}

.popover::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}