.fixed_wrapper {
  background-color: rgba(0, 128, 0, 0);
  position: fixed;
  bottom: 60px;
  display: flex;
  justify-content: center;
  z-index: 10;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform-origin: bottom;
  animation: translated 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
}

@keyframes translated {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 60px;
  }
}

.dialog_actions_menu {
  display: flex;
  position: relative;
  align-items: center;
  height: 60px;
  background-color: var(--boards-panel-background);
  border-radius: 9px;
  box-shadow: 0 0 20px 0 var(--shadow-default-color);
  pointer-events: auto;
}


.aside_col {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
}

.aside_left {
  background: linear-gradient(-90deg, #6dd1f1, #259ae9);
  color: white;
  font-size: 28px;
}

.selected_txt {
  padding-left: 10px;
  padding-right: 10px;
  // border-right: 1px solid var(--border-color);

  p {
    font-size: 18px;
    margin-inline-end:24px;
  }
}

.aside_left p {
  padding: 0;
  margin: 0;
}

.aside_right {
  cursor: pointer;
  background-color: var(--boards-panel-background);
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.aside_right:hover {
  color: #53bdeb;
}

.main_col {
  display: flex;
  flex: 1;
}

.bulk_action_btn_wrap {
  color: var(--secondary);

  .bulk_action_btn {
    cursor: pointer;
    display: grid;
    grid-template-columns: 100%;
    margin: 0 10px;
    border: 0;
    outline: none;
    padding: 8px;
    background-color: transparent;
    transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    color: var(--primary);
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;

    span svg {
      font-size: 18px;
    }
  }

  .bulk_action_btn:hover {
    color: #6dd1f1;
  }
}

@media only screen and (max-width: 768px) {
  .fixed_wrapper {
    background-color: rgba(0, 128, 0, 0);
    position: fixed;
    left:0px;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
    transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: bottom;
    animation: none;
  }
  .dialog_actions_menu {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    height: auto;
    flex-direction: column;
    background:var(--default-background);
    border-radius: 0;
    box-shadow: 0px 0px 12px 0px var(--box-shadow-color);
  
  }
  .main_col{
    flex-wrap: wrap;
  }
  .aside_left {
    position: absolute;
    left:0;
    border-radius: 0;
  }
  .selected_txt{
    border:none;
  }
  
.aside_right{
  position: absolute;
  right: 0;
  border-radius: 0;
  border: none;
}
  
}