.mediaSideBar {
    width: 5%;
    padding: 2% 1%;
    font-size: 14px;
    text-align: center;
    border-left: 1px solid #d0d4e4;
    .versionsSelected {
      background: #def7ff;
      border-radius: 8px;;
    }
    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      font-size: 0.9vw;
    }
  }