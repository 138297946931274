@import "../../mq.scss";

.row {
    display: flex;
}

.alignCenter {
    align-items: center;
}

.sidebar {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    flex: auto;

    .sidebarHeader {
        display: flex;
        align-items: center;
        padding: 0 16px;
        grid-gap: 24px;
        gap: 24px;
        height: 80px;
        box-sizing: border-box;

        // @include mq($until: desktop) {
        //     padding: 0 30px;
        // }

        .logo {
            height: 10px;
            width: 16px;
        }

        span {
            white-space: nowrap;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            color: var(--secondary-text-color);
        }
    }

    .menuContainer {
        display: flex;
        flex-direction: column;

        .menuHeader,
        .menuItem {
            padding: 8px 0;
        }

        .menuHeader {
            text-align: center;
            color: rgba(0, 0, 0, 0.5);
        }

        a {
            text-decoration: none;
        }

        .menuItem {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 16px;
            margin-bottom: 8px;
            color: var(--secondary-text-color);
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            width: 100%;
            box-sizing: border-box;
            border-radius: 8px;


            // @include mq($until: desktop) {
            //     padding: 8px 30px;
            // }

            .menuTitle {
                margin-inline-start: 15px;
            }

            a {
                text-decoration: none;
                color: var(--primary);
            }

            &:hover,
            &.active {
                background-color: var(--secondary-background-color);
            }
            
            &.active {
                font-weight: 600;
            }

            &.textOnly {
                background-color: transparent;
            }
        }
    }
}

.logo {
    width: 46px;
    height: 46px;
}