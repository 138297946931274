.form_header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 40px 12px;
    background: var(--secondary-background-color);
    z-index: 0;
    position: relative;
}


.form_wrapper {
    background-color: var(--background-default);
    width: 700px;
    border-radius: 20px;
    padding: 32px 48px;
}

.form_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.form_cell {
    border: transparent;
    // margin: 20px;
    padding: 16px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: box-shadow 0.1s;
    margin-top: 12px;
}

.form_cell:hover {
    box-shadow: 0 5px 12px rgb(0 0 0 / 15%);

    & .drag_handle {
        opacity: 1;
    }

    & .required_and_visible {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        visibility: visible;
    }
}

.form_column_cell {
    margin-block: 15px;
    margin-inline-start: 6px;
    display: flex;
    justify-content: flex-start;
    max-width: 300px;
}

.drag_handle {
    opacity: 0;
}

.form_cell {
    .required_and_visible {
        visibility: hidden;
    }
}

.settings_drawer_container {
    width: 320px;
    box-sizing: border-box;
    min-width: 0;
    height: 100%;
    z-index: 10;
    position: absolute;
    right: 0;
    background: var(--secondary-background-color);
    padding: 24px;
    box-shadow: 0 22px 20px 0 var(--shadow-default-color);
}

.settings_drawer .title {
    margin: 2px;
    font-size: 20px;
    font-weight: 800;
    font-family: 'Poppins', 'Assistant', sans-serif;
    margin-bottom: 24px;
    align-self: center;
}

.settings_drawer_title {
    display: flex;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Poppins';
}

.settings_drawer .option {
    padding-block: 8px 28px;
    padding-inline: 4px;
    border-top: 1px solid var(--search-input-border);
}

.settings_drawer .option.first {
    border-top: 0;
    margin-top: 8px;
}

.form_header .header_button {
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    height: 36px;
    padding: 0 16px;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 600;
    border: 0;
    background-color: transparent;
    outline: none;
    color: var(--secondary-text-color);
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    flex-shrink: 0;
}

.form_header .active,
.form_header .header_button:hover {
    opacity: 1;
    background: var(--button-hover);
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}