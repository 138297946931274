.importBtn {
  cursor: pointer !important;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 20px;
  border: 0;
  border-radius: 5px;
  color: #fff;
  background-color: #ff5722;
}


.importBtn:disabled {
  color: #8c8c8c !important;
  background-color: var(--disabled-import-button) !important;
}