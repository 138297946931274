.pickerContainer {
  background-color: var(--light-to-dark-color);
  position: absolute;
  left: -150px;
  top:0px;
  z-index: 10;
  box-shadow: 0px 1px 3px 0px var(--box-shadow-color);
  padding: 10px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  animation: translated 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes translated {
  0% {
    transform: translate(-100px);
  }
  100% {
    transform: translate(0px);
  }
}
